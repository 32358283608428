import Table from '@/common/components/Table';
import TextField from '@/common/components/TextField';
import { DatePickerWithRange } from '@/common/components/ui/dateRangePicker';
import useDebounce from '@/common/hooks/useDebounce';
import { useFilterParams } from '@/common/hooks/useFilterParams';
import { Mapper } from '@/common/services/mapper';
import { dateToISOStingTransformer } from '@/common/utils/dateTransformer';
import { SearchIcon } from 'lucide-react';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import { useEquipmentEvents } from '../../../hooks/useEquipmentEvents';
import { UserEventData, UserEventTableItem } from '../../../types/userEvents.type';
import { EquipmentEventsTransformer } from '../../../services/equipmentEventsTransformer';
import { equipmentConsumptionHistoryColumns } from '../../../constants/equipmentConsumptionHistoryColumns.constant';

export const ConsumptionHistory = () => {
  const { id: equipmentId } = useParams();

  const { filterParams, setFilterParams } = useFilterParams();

  const [search, setSearch] = useDebounce(filterParams.consumptionHistorySearch || '');
  const [page, setPage] = useState(1);

  const [date, setDate] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });

  const {
    data: equipmentEvents,
    meta,
    isFetching: equipmentEventsIsFetching,
  } = useEquipmentEvents({
    filters: {
      equipmentId,
      search,
      startDate: dateToISOStingTransformer(date?.from),
      endDate: dateToISOStingTransformer(date?.to),
    },
    mapper: Mapper.mapWithTransformer<UserEventData[], UserEventTableItem[]>({
      transformer: EquipmentEventsTransformer.tableTransformer(),
    }),
  });

  return (
    <div className="flex flex-col gap-6">
      <TextField
        LeftIcon={<SearchIcon />}
        placeholder="Pesquisar"
        className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
        defaultValue={filterParams.consumptionHistorySearch || ''}
        onChange={(e: any) => {
          setSearch(e.target?.value);
          setFilterParams((prev) => ({
            ...prev,
            consumptionHistorySearch: e.target?.value,
          }));
        }}
      />

      <div className="flex">
        <DatePickerWithRange date={date} setDate={setDate} />
      </div>

      <Table
        className="max-h-[calc(100vh-324px)]"
        columns={equipmentConsumptionHistoryColumns}
        isLoadingData={equipmentEventsIsFetching}
        rows={equipmentEvents}
        rowEmptyValue="--"
        page={page}
        setPage={(p) => setPage(p)}
        totalPages={meta.total_pages}
        totalCount={meta.total}
      />
    </div>
  );
};
