import { z } from 'zod'

export const createGovernmentAllocationSchema = z.object({
  branch_id: z.object(
    {
      label: z.string(),
      value: z.string(),
    },
    { message: 'Campo Filial é obrigatório' },
  ),
  start_of_document_validity: z
    .string({ message: 'Campo Início é obrigatório' })
    .min(10, { message: 'Use uma data válida' }),
  end_of_document_validity: z
    .string({ message: 'Campo Início é obrigatório' })
    .min(10, { message: 'Use uma data válida' }),
  office_number: z.string({ message: 'Campo Número do ofício é obrigatório' }).min(1, {
    message: 'Campo Número do ofício é obrigatório',
  }),
  total_value: z.string({ message: 'Campo Valor total é obrigatório' }).min(1, {
    message: 'Campo Valor total é obrigatório',
  }),
})

export type CreateGovernmentAllocationValidationSchema = z.infer<typeof createGovernmentAllocationSchema>
