import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper'
import ListFilters from '@/common/components/ListFilters'
import ListHeader from '@/common/components/ListHeader'
import { SheetFilters } from '@/common/components/SheetFilters'
import TextField from '@/common/components/TextField'
import { equipmentStatusTranslation } from '@/common/constants/equipmentStatus.constants'
import useDebounce from '@/common/hooks/useDebounce'
import { useFilterParams } from '@/common/hooks/useFilterParams'
import { Mapper } from '@/common/services/mapper'
import { options as propertyKindOptions } from '@/features/equipments/constants/propertyKindOptions.constant'
import { options as usageKindOptions } from '@/features/equipments/constants/usageKindOptions.constant'
import { CarFront, SearchIcon } from 'lucide-react'
import { useEquipmentCategories } from '../../hooks/useEquipmentCategories'
import { useEquipments } from '../../hooks/useEquipments'
import { EquipmentCategoryData, EquipmentData } from '../../types/equipments.type'
import { EquipmentsGridTable } from '@/features/equipments/components/EquipmentsGridTable'

const EquipmentsList = () => {
  const { filterParams, setFilterParams } = useFilterParams()

  const [searchEquipmentCategory, setSearchEquipmentCategory] = useDebounce('')

  const {
    data: companyEquipments,
    meta,
    isPending: companyEquipmentsIsPending,
    error: companyEquipmentsError = {} as any,
    refetch: refetchCompanyEquipments,
  } = useEquipments<EquipmentData[]>({
    filters: {
      search: filterParams.search,
      page: filterParams.page,
      status: filterParams.status?.value,
      categoryId: filterParams.category?.value,
      usageKind: filterParams.usage_kind,
      propertyKind: filterParams.property_kind,
    },
  })

  const { data: equipmentCategoryOptions } = useEquipmentCategories({
    filters: { search: searchEquipmentCategory },
    mapper: Mapper.mapToOptions<EquipmentCategoryData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  })

  return (
    <div className="flex flex-col gap-6">
      <ListHeader
        Icon={<CarFront className="w-10 h-10" strokeWidth={1.5} />}
        title="Equipamentos"
        createButtonText="Novo equipamento"
        disableCreateButton={companyEquipmentsIsPending || companyEquipmentsError?.status === 403}
      />

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={companyEquipmentsError?.status}
        refetchFunction={refetchCompanyEquipments}
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <TextField
              LeftIcon={<SearchIcon />}
              placeholder="Pesquisar"
              className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
              defaultValue={filterParams.search || ''}
              onChange={(e: any) => setFilterParams((prev) => ({ ...prev, search: e.target?.value }))}
            />

            <div className="flex gap-2 items-center justify-start">
              <ListFilters
                filters={[
                  {
                    className: '',
                    onChange: (e) => setFilterParams((prev) => ({ ...prev, category: e })),
                    onInputChange: setSearchEquipmentCategory,
                    options: [{ label: 'Todas', value: '' }, ...(equipmentCategoryOptions || [])],
                    value: filterParams.category || '',
                    placeholder: 'Tipo de Equipamento',
                  },
                  {
                    className: '',
                    onChange: (e) => setFilterParams((prev) => ({ ...prev, status: e })),
                    options: [
                      { label: 'Todas', value: '' },
                      ...Object.entries(equipmentStatusTranslation).map(([key, value]) => ({
                        label: value,
                        value: key,
                      })),
                    ],
                    value: filterParams.status || '',
                    placeholder: 'Situação',
                  },
                ]}
              />

              <SheetFilters
                filters={[
                  {
                    label: 'Tipo de Equipamento',
                    options: [
                      {
                        label: 'Todos',
                        value: '',
                        selected: filterParams.category?.value === '',
                        onClick: () => setFilterParams((prev) => ({ ...prev, category: '' })),
                      },
                      ...(equipmentCategoryOptions?.map(({ label, value }) => ({
                        label,
                        value,
                        selected: filterParams.category?.value === value,
                        onClick: () => setFilterParams((prev) => ({ ...prev, category: { value, label } })),
                      })) || []),
                    ],
                  },
                  {
                    label: 'Situação',
                    options: [
                      {
                        label: 'Todos',
                        value: '',
                        selected: filterParams.status?.value === '',
                        onClick: () => setFilterParams((prev) => ({ ...prev, status: '' })),
                      },
                      ...Object.entries(equipmentStatusTranslation).map(([key, value]) => ({
                        label: value,
                        value: key,
                        selected: filterParams.status?.value === key,
                        onClick: () => setFilterParams((prev) => ({ ...prev, status: { value: key, label: value } })),
                      })),
                    ],
                  },
                  {
                    label: 'Dias sem compra',
                    options: [
                      {
                        label: 'Não implementado',
                        value: '',
                      },
                    ],
                  },
                  {
                    label: 'Tipo de uso',
                    options: [
                      {
                        label: 'Todos',
                        value: '',
                        selected: filterParams.usage_kind === '',
                        onClick: () => setFilterParams((prev) => ({ ...prev, usage_kind: '' })),
                      },
                      ...usageKindOptions.map(({ label, value }) => ({
                        label,
                        value,
                        selected: filterParams.usage_kind === value,
                        onClick: () => setFilterParams((prev) => ({ ...prev, usage_kind: value })),
                      })),
                    ],
                  },
                  {
                    label: 'Tipo de propriedade',
                    options: [
                      {
                        label: 'Todos',
                        value: '',
                        selected: filterParams.property_kind === '',
                        onClick: () => setFilterParams((prev) => ({ ...prev, property_kind: '' })),
                      },
                      ...propertyKindOptions.map(({ label, value }) => ({
                        label,
                        value,
                        selected: filterParams.property_kind === value,
                        onClick: () => setFilterParams((prev) => ({ ...prev, property_kind: value })),
                      })),
                    ],
                  },
                ]}
              />
            </div>
          </div>

          <div>
            <EquipmentsGridTable
              className="max-h-[calc(100vh-280px)]"
              companyEquipments={companyEquipments || []}
              page={filterParams.page || 1}
              totalPages={meta.total_pages || 1}
              totalCount={meta.total}
              setPage={(page) => setFilterParams((prev) => ({ ...prev, page }))}
              isLoadingData={companyEquipmentsIsPending}
            />
          </div>
        </div>
      </ErrorMessageWrapper>
    </div>
  )
}

export default EquipmentsList
