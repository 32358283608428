import { transformToNumber } from '@/common/utils/transformers'
import { differenceInCalendarMonths, getDaysInMonth } from 'date-fns'

export const getBarLeftPosition = () => {
  const date = new Date()
  const today = date.getDate()
  const daysInMonth = getDaysInMonth(date)
  const position = ((today / daysInMonth) * 100).toFixed(0)
  return `${position}%`
}

export const getCardLeftPosition = ({
  applicableStartDate,
  year,
  month,
}: {
  applicableStartDate: Date
  year: number
  month: number
}) => {
  const startDay = applicableStartDate.getDate()
  const monthsDiff = differenceInCalendarMonths(new Date(year, month), applicableStartDate)

  if (monthsDiff > 0 && month === 0) {
    return '-5%'
  }

  if (startDay === 1) {
    return `${transformToNumber({ value: monthsDiff * 100, invert: true })}%`
  }

  const daysInMonth = getDaysInMonth(new Date(year, month - monthsDiff))
  const position = Number(((startDay / daysInMonth) * 100).toFixed(0)) - monthsDiff * 100

  return `${position}%`
}

export const getCardRightPosition = ({
  applicableEndDate,
  year,
  month,
}: {
  applicableEndDate: Date
  year: number
  month: number
}) => {
  const endDay = applicableEndDate.getDate()
  const monthsDiff = differenceInCalendarMonths(applicableEndDate, new Date(year, month))
  const daysInMonth = getDaysInMonth(new Date(year, month - monthsDiff))

  if (monthsDiff === 0) {
    const position = transformToNumber({
      value: Number(((endDay / daysInMonth) * 100).toFixed(0)) - 100,
      invert: true,
    })

    return `${position}%`
  }

  const position = transformToNumber({
    value: Number(((endDay / daysInMonth) * 100).toFixed(0)) + (monthsDiff - 1) * 100,
    invert: true,
  })

  return `${position}%`
}

export const getConsumedPercentage = ({
  approved_value,
  consumed_total,
}: {
  approved_value: string
  consumed_total: string
}) => {
  const approved = Number(approved_value)
  const consumed = Number(consumed_total)
  return ((consumed / approved || 1) * 100).toFixed(0)
}
