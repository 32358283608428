import '@xyflow/react/dist/style.css';
import Button from '@/common/components/Button';
import { Mapper } from '@/common/services/mapper';
import { CompanyBranchData, CompanyBranchTransformerToStructureResponse } from '@/common/types/companyBranches.type';
import { Node, ReactFlow } from '@xyflow/react';
import { useMemo, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import CustomNode from '../../components/structure/CustomNode';
import { useCompanyBranches } from '@/common/hooks/queries/useCompanyBranches';
import { getLayoutedElements } from '../../services/getLayoutElements';
import '../../styles/style.css';
import { CompanyBranchesTransformer } from '../../services/companyBranchesTransformer';
import ListHeader from '@/common/components/ListHeader';
import { GitFork } from 'lucide-react';
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper';

const nodeTypes = {
  custom: CustomNode,
};

const CompanyBranchesStructure = () => {
  const { id } = useParams();
  const parentRef = useRef<HTMLDivElement>(null);

  const {
    data: { nodes, edges },
    isPending: isLoadingBranches,
    error: branchesError = {} as any,
    refetch: refetchBranches,
  } = useCompanyBranches({
    filters: { pageSize: 1000 },
    mapper: Mapper.mapWithTransformer<CompanyBranchData[], CompanyBranchTransformerToStructureResponse>({
      transformer: CompanyBranchesTransformer.structureTransformer(),
    }),
  });

  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(() => {
    return getLayoutedElements(nodes, edges);
  }, [id, nodes, edges]);

  if (isLoadingBranches) return <div>Carregando...</div>;

  return (
    <div className="flex flex-col gap-6">
      <ListHeader
        Icon={<GitFork className="w-10 h-10" strokeWidth={1.5} />}
        title="Estrutura"
      />

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={branchesError?.status}
        refetchFunction={refetchBranches}
      >
        <div className='flex flex-col bg-white-100 rounded-md items-center p-4 gap-4'>
          <div className='relative flex justify-between w-full'>
            <p>Estrutura da empresa</p>

            <Link to='create'>
              <Button>Adicionar filial</Button>
            </Link>
          </div>

          <div className='h-[calc(100vh-280px)] w-full' ref={parentRef}>
            <ReactFlow
              nodes={layoutedNodes?.map((node: Node) => ({
                ...node,
                data: { ...node.data, parentRef },
              }))}
              edges={layoutedEdges}
              nodeTypes={nodeTypes}
              fitView={true}
            />
          </div>
        </div>
      </ErrorMessageWrapper>
    </div>
  );
};

export default CompanyBranchesStructure;
