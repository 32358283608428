import { Link } from "react-router-dom";
import { ArrowBigRight, Building2 } from "lucide-react";
import { formatCurrency, formatDate } from "@/common/utils/formatters";
import React from "react";
import { billingEventsFiscalDocumentsStatus } from "../constants/eventsFiscalDocumentsStatusOptions.constant";
import { FiscalDocumentGroupData } from "../types/fiscalDocumentGroup.type";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";
import CompanyConsumptionDetails from "@/features/equipments/components/show/consumptionHistory/CompanyConsumptionDetails";
import CompanyRefundDetails from "@/features/equipments/components/show/consumptionHistory/CompanyRefundDetails";

export class BillingFiscalDocumentGroupsTransformer {
  static tableTransformer() {
    return (groups: FiscalDocumentGroupData[]) => {
      return groups.map((group) => {
        return {
          ...group,
          status: group.status ? billingEventsFiscalDocumentsStatus[group.status as keyof typeof billingEventsFiscalDocumentsStatus] : '',
          client_company_branch: group?.client_branch?.display_name,
          establishment_company_branch: group?.establishment_branch?.general_information && (
            <div className="flex items-center gap-2">
              <ImageWithPlaceholder
                placeholderIcon={Building2}
                className="w-6 h-6"
                iconClassName="w-4 h-4"
                url={group?.establishment_branch?.general_information?.company_logo?.url}
              />

              <p>
                {group?.establishment_branch?.general_information?.fantasy_name}
              </p>
            </div>
          ),
          consumptions_amount: formatCurrency(Number(group.consumptions_amount || 0)),
          consumptions_count: Number(group.consumptions_count),
          opened_at: group.opened_at ? formatDate(new Date(`${group.opened_at} `)) : '',
          closed_at: group.closed_at ? formatDate(new Date(`${group.closed_at} `)) : '',
          upload_due_time: 'Não implementado',
          action: (
            <Link to={group.id}>
              <ArrowBigRight />
            </Link>
          ),
        };
      });
    };
  }
}

export const eventableDetailsComponent: { [key: string]: React.ComponentType<any>; } = {
  'Client::Consumption': CompanyConsumptionDetails,
  'Billing::Refund': CompanyRefundDetails,
};
