import Button from '@/common/components/Button'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'

interface AnnualFilterProps {
  setFilterParams: (mutationFn: (searchParams: any) => any) => void
  year: string
  disabled?: boolean
}

export const AnnualFilter = ({ setFilterParams, year, disabled }: AnnualFilterProps) => {
  return (
    <div className="flex space-x-2 items-center justify-end">
      <Button
        variant="link-white"
        onClick={() => setFilterParams((prev) => ({ ...prev, year: Number(year) - 1 }))}
        disabled={disabled}
      >
        <ChevronLeftIcon strokeWidth={1} />
      </Button>
      <span>{year}</span>
      <Button
        variant="link-white"
        onClick={() => setFilterParams((prev) => ({ ...prev, year: Number(year) + 1 }))}
        disabled={disabled}
      >
        <ChevronRightIcon strokeWidth={1} />
      </Button>
    </div>
  )
}
