export const fiscalDocumentGroupsDefaultColumns = [
  { label: 'Status', value: 'status' },
  { label: 'Filial da empresa', value: 'client_company_branch' },
  { label: 'Estabelecimento', value: 'establishment_company_branch' },
  { label: 'Valor total', value: 'consumptions_amount' },
  { label: 'Qntd. transações', value: 'consumptions_count' },
  { label: 'Abertura', value: 'opened_at' },
  { label: 'Abertura', value: 'closed_at' },
  { label: 'Prazo limite recolha', value: 'upload_due_time' },
  { label: 'Ações', value: 'action', className: 'w-9' },
];
