import Box from '@/common/components/Box'
import Button from '@/common/components/Button'
import { MultipleDocumentUploader } from '@/common/components/MultipleDocumentUploader'
import SelectController from '@/common/components/SelectController'
import TextArea from '@/common/components/TextArea'
import TextField from '@/common/components/TextField'
import useBlobs from '@/common/hooks/queries/useBlobs'
import { useGoBack } from '@/common/hooks/useGoBack'
import useUser from '@/common/hooks/useUser'
import { Mapper } from '@/common/services/mapper'
import { FormTransformers } from '@/common/utils/formTransformers'
import { useCreateSupportTicket } from '@/features/support/hooks/useCreateSupportTicket'
import { useSupportReasons } from '@/features/support/hooks/useSupportReasons'
import { supportTicketSchema, SupportTicketValidationSchema } from '@/features/support/schemas/create.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { ChevronLeftIcon } from 'lucide-react'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

const Create = () => {
  const goBack = useGoBack()
  const queryClient = useQueryClient()

  const [baseError, setBaseError] = useState<string[]>([])

  const { data: supportReasonOptions } = useSupportReasons({
    mapper: Mapper.mapToOptions({
      labelFieldName: 'display_name',
      valueFieldName: 'id',
    }),
  })

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<SupportTicketValidationSchema>({
    resolver: zodResolver(supportTicketSchema),
  })

  const { data: user } = useUser({ id: 'me' })

  const { mutateAsync: uploadBlob, isPending: uploadBlobIsPending } = useBlobs()

  const { mutate: createSupportTicket, isPending: createSupportTicketIsPending } = useCreateSupportTicket({
    creator_id: user?.id,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['supportTickets'] })
      goBack({ fallback: '/support' })
    },
    onError: (error) => {
      if (error?.response?.data?.errors?.base?.length) {
        setBaseError(error.response.data.errors.base)
        return
      }

      FormTransformers.errorsTransformer<SupportTicketValidationSchema>({
        setError,
      })(error)
    },
  })

  const onSubmit: SubmitHandler<SupportTicketValidationSchema> = (data) => {
    createSupportTicket(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex gap-4 items-center mb-6">
        <Button variant="link-white" onClick={() => goBack({ fallback: '/support' })}>
          <ChevronLeftIcon size={40} strokeWidth={1} />
        </Button>

        <h2 className="text-2xl font-bold">Abertura de chamado</h2>
      </div>

      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto h-[calc(100vh-128px)]">
        {baseError?.map((error) => (
          <p className="text-sm text-danger-soft">{error}</p>
        ))}

        <SelectController
          control={control}
          name="reason"
          label="Motivo"
          errorMessage={errors.reason?.message}
          options={supportReasonOptions || []}
          isDisabled={createSupportTicketIsPending}
        />

        <TextField
          label="Assunto"
          errorMessage={errors.subject_line?.message}
          {...register('subject_line')}
          disabled={createSupportTicketIsPending}
        />

        <TextArea rows={16} maxLength={2048} errorMessage={errors.content?.message} {...register('content')} />

        <MultipleDocumentUploader
          control={control}
          name="documents"
          uploadBlob={uploadBlob}
          fieldName="documents"
          accept="application/pdf"
          disabled={createSupportTicketIsPending || uploadBlobIsPending}
        />

        <Button
          type="submit"
          className="self-end"
          disabled={createSupportTicketIsPending || uploadBlobIsPending}
          isLoading={createSupportTicketIsPending}
        >
          Enviar
        </Button>
      </Box>
    </form>
  )
}

export default Create
