import { GovernmentAllocationEntryAwaitingManagementReviewForm } from "@/features/governmentAllocations/pages/Update/components/governmentAllocationEntryFormStates/GovernmentAllocationEntryAwaitingManagementReviewForm";
import { GovernmentAllocationEntryCreatedForm } from "@/features/governmentAllocations/pages/Update/components/governmentAllocationEntryFormStates/GovernmentAllocationEntryCreatedForm";

export const governmentAllocationFormStateOptions = [
  {
    value: 'created',
    comp: GovernmentAllocationEntryCreatedForm,
  },
  {
    value: 'awaiting_management_review',
    comp: GovernmentAllocationEntryAwaitingManagementReviewForm,
  },
]
