import Box from '@/common/components/Box'
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper'
import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder'
import useUser from '@/common/hooks/useUser'
import { MessageData } from '@/common/types/support/message.type'
import { cn } from '@/common/utils/cn'
import { FormMessage } from '@/features/support/components/FormMessage'
import { Message } from '@/features/support/components/Message'
import { strategy as statusStrategy } from '@/features/support/constants/statusOptions.constant'
import { useSupportTicket } from '@/features/support/hooks/useSupportTicket'
import { useSupportTicketMessages } from '@/features/support/hooks/useSupportTicketMessages'
import { BriefcaseBusinessIcon, ChevronLeftIcon, UserRoundIcon } from 'lucide-react'
import { Link, useParams } from 'react-router-dom'

const Show = () => {
  const { id: supportTicketId } = useParams()

  const { data: user } = useUser({ id: 'me' })

  const {
    data: supportTicketData,
    isPending: supportTicketDataIsPending,
    error: supportTicketDataError = {} as any,
    refetch: refetchSupportTicket,
  } = useSupportTicket({
    id: supportTicketId || '',
  })

  const { data: supportTicketMessagesData } = useSupportTicketMessages<MessageData[]>({
    id: supportTicketId || '',
  })

  return (
    <>
      {supportTicketDataIsPending ? (
        <Box>Carregando...</Box>
      ) : (
        <ErrorMessageWrapper
          className="max-h-[calc(100vh-128px)]"
          statusCode={supportTicketDataError?.status}
          refetchFunction={refetchSupportTicket}
        >
          <div>
            <div className="flex justify-between items-center mb-6">
              <div className="flex gap-4 items-center">
                <Link to={'/support'} className="cursor-pointer">
                  <ChevronLeftIcon size={40} strokeWidth={1} />
                </Link>

                <div className="flex gap-2 items-center">
                  <h2 className="text-2xl font-bold">{supportTicketData?.reference_number}</h2>

                  <div
                    className={cn(
                      'px-2 py-1 rounded-sm',
                      supportTicketData?.status &&
                        ['pending', 'awaiting_tcard_response'].includes(supportTicketData?.status) &&
                        'bg-yellow-op-2',
                      supportTicketData?.status &&
                        ['awaiting_customer_response', 'closed'].includes(supportTicketData?.status) &&
                        'bg-blue-200',
                    )}
                  >
                    {statusStrategy[supportTicketData?.status as keyof typeof statusStrategy]}
                  </div>
                </div>
              </div>
            </div>

            <Box className="flex flex-col gap-6 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto h-[calc(100vh-128px)]">
              <div className="flex flex-col gap-4">
                <p className="text-lg font-bold">Autor</p>

                <div className="flex gap-2 items-center">
                  <ImageWithPlaceholder
                    url={supportTicketData?.creator?.profile_picture?.url}
                    alt={supportTicketData?.creator?.full_name}
                    placeholderIcon={UserRoundIcon}
                    className="size-8"
                    iconClassName="size-6"
                  />

                  <p className="text-sm font-bold">{supportTicketData?.creator?.full_name}</p>

                  <p className="text-sm font-light italic">de</p>

                  <ImageWithPlaceholder
                    url={supportTicketData?.creator?.branch?.general_information?.company_logo?.url}
                    alt={supportTicketData?.creator?.branch?.display_name}
                    placeholderIcon={BriefcaseBusinessIcon}
                    className="size-8"
                    iconClassName="size-6"
                  />

                  <p className="text-sm font-bold">{supportTicketData?.creator?.branch?.display_name}</p>
                </div>
              </div>

              {supportTicketMessagesData?.map((message) => (
                <Message
                  key={message.id}
                  message={message}
                  margin={message.creator.id === user?.id ? 'left' : 'right'}
                />
              ))}

              {!!supportTicketData && supportTicketData?.status !== 'closed' && (
                <FormMessage supportTicketId={supportTicketData?.id} />
              )}
            </Box>
          </div>
        </ErrorMessageWrapper>
      )}
    </>
  )
}

export default Show
