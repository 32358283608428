import { BillingEventData } from "@/common/types/billing/event.type";
import { formatCurrency } from "@/common/utils/formatters";
import { useEffect, useRef, useState } from "react";
import TooltipPattern from "@/common/components/TooltipPattern";
import { X } from "lucide-react";
import Table from "@/common/components/Table";
import feesDetailsTableColumnsConstant from "../constants/feesDetailsTableColumns.constant";
import { BillingEventTransformer } from "../services/billingEventTransformer";
import useBillingEvent from "@/common/hooks/queries/useBillingEvent";
import { twMerge } from "tailwind-merge";

const FeeDetails = ({ id, fee_total }: BillingEventData) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const totalFee = Number(fee_total);

  const elementRef = useRef<HTMLDivElement>(null);

  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const { data: billingEvent, isFetched, isFetching } = useBillingEvent({ id, enabled: !!((isOverlayVisible && id) || loaded) });

  useEffect(() => {
    if (isFetched) {
      setLoaded(true);
    }
  }, [isFetched]);

  return (
    <div onMouseEnter={() => setOverlayVisible(true)} onMouseLeave={() => setOverlayVisible(false)}>
      <div ref={elementRef} className={twMerge("cursor-default py-4", totalFee > 0 ? 'text-success' : totalFee < 0 ? 'text-danger-soft' : '')} >
        {formatCurrency(totalFee)}
      </div>

      {isOverlayVisible && (
        <TooltipPattern target={elementRef.current} expectedWidth={350} expectedHeight={250}>
          <div className="bg-white-200 rounded-lg overflow-hidden border-gray-300 shadow-lg">
            <div className="bg-[#545c5c] flex items-center justify-between gap-2 p-6">
              <p className="text-[18px]">Detalhamento de tarifas</p>

              <div className="cursor-pointer" onClick={() => setOverlayVisible(false)}>
                <X className="h-6 w-6" color="red" />
              </div>
            </div>

            <div className="bg-[#2c2c2c] pr-1">
              <div className="bg-[#2c2c2c] max-h-[170px] overflow-y-auto pr-1">
                <Table
                  headerClassName="rounded-none"
                  headerCellClassName="py-4 rounded-none"
                  footerClassName="rounded-none"
                  flatHeader
                  className="rounded-none bg-[#444444]"
                  columns={feesDetailsTableColumnsConstant}
                  rows={BillingEventTransformer.feesDetailsTableTransformer()(billingEvent?.fees || [])}
                  rowEmptyValue="--"
                  page={1}
                  totalPages={1}
                  isLoadingData={isFetching}
                />
              </div>
            </div>
          </div>
        </TooltipPattern>
      )}
    </div>
  );
};

export default FeeDetails;
