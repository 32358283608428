import { forwardRef, createRef, Ref } from "react";
import { IMaskInput } from "react-imask";
import IMask from "imask";
import { TextFieldProps } from "../TextField";
import { twMerge } from "tailwind-merge";
import { cn } from "@/common/utils/cn";
import { cva } from "class-variance-authority";

export interface MaskedTextField extends Omit<TextFieldProps, 'onChange'> {
  onChange?: (event: { target: { name: string; value: string; }; }) => void;
  name?: string;
  mask: any;
  markAsRequired?: boolean;
  isLoading?: boolean;
}

const textFieldVariants = cva(
  'p-4 h-[52px] w-full text-white-800 text-sm rounded-md border bg-neutral-op-8 outline-none border border-transparent',
  {
    variants: {
      variant: {
        default: '',
        disabled: 'text-white-300',
        error: 'border-danger'
      }
    },
    defaultVariants: {
      variant: 'default',
    }
  }
);

const MaskedTextField = forwardRef<typeof IMask.MaskElement, MaskedTextField>(({
  label,
  markAsRequired,
  variant,
  className,
  disabled = false,
  placeholder,
  RightIcon,
  LeftIcon,
  errorMessage,
  onIconClick,
  onChange,
  mask,
  isLoading,
  type = 'text',
  value,
  ...props
}, inputRef) => {
  const ref = createRef();
  return (
    <div className={className}>
      <label className="relative flex flex-col items-start justify-center">
        {label ? <p className={twMerge('text-xs', disabled ? '' : '', 'mb-1')}>{label}{markAsRequired && <span className="text-danger-soft"> *</span>}</p> : null}
        {
          RightIcon
            ?
            <div
              className={twMerge('absolute right-0 bottom-0 -translate-x-1/2 -translate-y-[calc(50%+4px)]', onIconClick && 'cursor-pointer')}
              onClick={onIconClick}
            >
              {RightIcon}
            </div>
            : null
        }
        {
          isLoading &&
          <div
            className={twMerge('absolute right-0 bottom-0 -translate-x-1/2 -translate-y-[calc(50%+4px)]', onIconClick && 'cursor-pointer')}
          >
            <div className="w-4 h-4 border-4 border-dashed rounded-full animate-spin border-blue-800 mb-1 mr-1" />
          </div>
        }
        {
          LeftIcon
            ?
            <div
              className={twMerge('absolute left-0 bottom-0 translate-x-1/2 -translate-y-[calc(50%+4px)]', onIconClick && 'cursor-pointer')}
              onClick={onIconClick}
            >
              {LeftIcon}
            </div>
            : null
        }
        <IMaskInput
          {...props}
          inputRef={inputRef as Ref<HTMLInputElement>}
          ref={ref}
          value={value as string | undefined}
          mask={mask}
          onAccept={(value: any) => {
            onChange && onChange({ target: { name: props.name || '', value } });
          }}
          className={cn(textFieldVariants({ variant: disabled ? 'disabled' : variant }), LeftIcon && 'pl-10')}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
      </label>
      {errorMessage?.length && <span className="text-danger-soft text-sm">{errorMessage}</span>}
    </div>
  );
});

export default MaskedTextField;
