import usePrivateAxios from '@/common/api/usePrivateAxios'
import { ErrorResponse } from '@/common/types/errorResponse'
import { unFormatCurrency } from '@/common/utils/unFormatters'
import { GovernmentAllocationEntryUpdateValidationSchema } from '@/features/governmentAllocations/schemas/update.schema'
import { useMutation } from '@tanstack/react-query'

interface UseUpdateGovernmentAllocationEntry {
  governmentAllocationId?: string
  onSuccess: () => void
  onError: (error: ErrorResponse) => void
}

export const useUpdateGovernmentAllocationEntry = ({
  governmentAllocationId,
  onSuccess,
  onError,
}: UseUpdateGovernmentAllocationEntry) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['updateGovernmentAllocationEntry'],
    mutationFn: async (data: GovernmentAllocationEntryUpdateValidationSchema) => {
      const government_allocation_entry = {
        ...data,
        total_value: data.total_value ? unFormatCurrency(data.total_value) : undefined,
      }

      await privateAxios.put(`/billing/government_allocation_entries/${governmentAllocationId}`, {
        government_allocation_entry,
      })
    },
    onSuccess,
    onError,
  })
}
