import { formatDate } from "@/common/utils/formatters";
import { FiscalDocumentGroupData } from "../../types/fiscalDocumentGroup.type";

const BillingResume = ({ billing }: { billing?: FiscalDocumentGroupData; }) => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-xl font-bold">Resumo do lote</h3>

      <div>
        <div className="flex flex-col gap-4">
          {billing?.establishment_branch?.general_information?.fantasy_name && (
            <div className="flex flex-col gap-1">
              <p className="italic font-light text-xs">Estabelecimento</p>
              <p className="font-bold text-md">{billing?.establishment_branch?.general_information?.fantasy_name}</p>
            </div>
          )}

          <div className="flex gap-8">
            {billing?.opened_at && (
              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Abertura</p>
                <p className="font-bold text-md">{formatDate(new Date(`${billing?.opened_at} `))}</p>
              </div>
            )}

            {billing?.closed_at && (
              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Fechamento</p>
                <p className="font-bold text-md">{formatDate(new Date(`${billing?.closed_at} `))}</p>
              </div>
            )}
          </div>

          {billing?.client_branch && (
            <div className="flex gap-8">
              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Filial</p>

                <p>
                  {billing?.client_branch?.display_name || '--'}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingResume;
