import { formatCurrency } from "@/common/utils/formatters";
import { EstablishmentCompanyData } from "@/features/company/types/company.type";
import { formatDateTimePriceUpdatedAt } from "../../utils/formatters";
import { unities } from "@/common/constants/unities.constants";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";
import { FuelIcon } from "lucide-react";

export const EstablishmentTooltipDetails = ({ establishment }: { establishment?: EstablishmentCompanyData; }) => (
  <>
    {establishment?.matrix_branch?.photos[0]?.url && (
      <div className="w-full h-[128px] rounded-t-md">
        <img
          src={establishment.matrix_branch.photos[0].url}
          className="object-fill w-full h-full rounded-t-md"
        />
      </div>
    )}
    <div
      className={`flex flex-col p-3 w-[336px] bg-slate-50/10 ${!establishment?.matrix_branch?.photos[0]?.url ? 'rounded-t-md' : ''}`}
    >
      <div className="flex gap-2 items-center">
        <ImageWithPlaceholder
          url={establishment?.general_information?.company_logo?.url}
          placeholderIcon={FuelIcon}
          className="h-6 w-6"
          iconClassName="h-4 w-4"
        />

        <p className="font-bold text-lg">{establishment?.general_information?.fantasy_name}</p>
      </div>
      <p className="font-light mt-1 text-sm -skew-x-6">
        {`${establishment?.general_information?.address.street},
          ${establishment?.general_information?.address.number} -
          ${establishment?.general_information?.address.city?.state.display_name ? establishment?.general_information?.address.city?.state.display_name + ',' : ''}
          ${establishment?.general_information?.address.city?.display_name}`}
      </p>
      <p className="italic font-light text-sm -skew-x-6">Aberto - Não implementado</p>
    </div>

    {Number(establishment?.product_prices?.length) > 0 && (
      <div className="flex-col p-3 rounded-b-md">
        <p className="text-base font-normal">Produtos</p>
        <div className="flex items-end mt-1">
          {establishment?.product_prices?.slice(0, 3).map((productPrice, index) => (
            <>
              {index > 0 && <div className="bg-gray-800 w-[1px] h-10 mr-2 self-center" />}

              <div className="flex-col w-1/3">
                <p className="font-bold text-xs">
                  {productPrice.product.display_name}
                </p>

                <p className="font-normal text-lg">
                  {formatCurrency(Number(productPrice.value) || 0)}

                  <span className="font-light text-sm text-white-400">
                    {unities[productPrice.product.unit_for_quantity] ? `/` : ''}{unities[productPrice.product.unit_for_quantity]}
                  </span>
                </p>

                <p className="font-thin italic -skew-x-6 text-white-300">
                  {productPrice.updated_at && formatDateTimePriceUpdatedAt(new Date(productPrice.updated_at))}
                </p>
              </div>
            </>
          ))}
        </div>
      </div>
    )}
  </>
);
