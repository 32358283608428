import { getConsumedPercentage } from '@/features/governmentAllocations/services/getLayoutGovernmentAllocation'
import { GovernmentAllocationData } from '@/features/governmentAllocations/types/governmentAllocation.type'
import { format } from 'date-fns'

type GovernmentAllocationCardDetailsProps = {
  governmentAllocation: GovernmentAllocationData
}

export const GovernmentAllocationCardDetails = ({ governmentAllocation }: GovernmentAllocationCardDetailsProps) => {
  const applicableStartDate = new Date(governmentAllocation.applicable_start_date)
  const applicableEndDate = new Date(governmentAllocation.applicable_end_date)

  return (
    <>
      <div className="border border-transparent border-l-blue-800 m-2 pl-[2px]">
        <p className="text-[10px] font-light italic leading-3">{format(applicableStartDate, 'dd/MM')}</p>
        <p className="text-[10px] font-light italic leading-3">{format(applicableEndDate, 'dd/MM')}</p>
      </div>

      <p className="m-2">
        {getConsumedPercentage({
          approved_value: governmentAllocation.approved_value,
          consumed_total: governmentAllocation.consumed_total,
        })}
        %
      </p>
    </>
  )
}
