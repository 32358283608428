import { FC } from "react";
import { Pagination } from "./pagination";
import Rows from "./rows";
import { twMerge } from "tailwind-merge";
import { EmptyDataMessage } from "@/common/components/EmptyDataMessage";

export interface ColumnPattern {
  label: string;
  value: string;
  className?: string;
  hidden?: boolean;
}

export interface TableProps {
  className?: string;
  headerClassName?: string;
  headerCellClassName?: string;
  footerClassName?: string;
  flatHeader?: boolean;
  columns: ColumnPattern[];
  rows: { [key: string]: unknown }[];
  page: number;
  totalPages: number;
  totalCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  isLoadingData: boolean;
  loadingRows?: number;
  hasPagination?: boolean;
  bottomContent?: JSX.Element;
  rowEmptyValue?: string;
}

const Table: FC<TableProps> = ({
  className,
  headerClassName,
  headerCellClassName,
  footerClassName,
  flatHeader,
  columns,
  rows,
  page,
  totalPages,
  totalCount,
  setPage,
  isLoadingData,
  loadingRows,
  hasPagination = false,
  bottomContent = null,
  rowEmptyValue,
}) => {
  return isLoadingData || !!rows.length ? (
    <div className={twMerge('flex flex-col max-h-[73vh] rounded-lg overflow-hidden', className)}>
      <div className="overflow-auto">
        <table className="text-sm w-full  whitespace-nowrap">
          <thead className={twMerge('bg-[#212023] sticky top-0 z-10', headerClassName)}>
            <tr className={!flatHeader ? "[&>*:last-child]:rounded-tr-lg [&>*:first-child]:rounded-tl-lg [&>*:first-child]:border-l-0 [&>*:last-child]:border-r-0" : ""}>
              {columns.filter((column) => !column.hidden).map((column) => (
                <th
                  className={twMerge(
                    'text-left px-4 border-x z-30 border-x-[#64676980] py-6 text-sm font-bold',
                    headerCellClassName,
                  )}
                  key={column.value}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="[&>*:nth-child(odd)]:bg-white-100 [&>*:nth-child(even)]:bg-blue-100">
            <Rows
              data={rows}
              columns={columns}
              isLoading={isLoadingData}
              loadingRows={totalPages > 1 ? 50 : loadingRows}
              emptyValue={rowEmptyValue}
            />
          </tbody>
        </table>
      </div>
      <div
        className={twMerge(
          "w-full px-4 py-2 bg-black-300 rounded-b-lg flex justify-between items-center ",
          footerClassName,
          typeof totalCount === "undefined" && "justify-end"
        )}
      >
        {typeof totalCount !== "undefined" ? (
          <p className="text-xs italic font-light">
            Mostrando {rows.length} resultados de {totalCount}
          </p>
        ) : null}
        {hasPagination && setPage ? (
          <Pagination
            currentPage={Number(page)}
            setPage={setPage}
            totalPages={totalPages}
          />
        ) : (
          bottomContent
        )}
      </div>
    </div>
  ) : (
    <EmptyDataMessage className={className} />
  );
};

export default Table;
