import { twMerge } from "tailwind-merge";

export type BoxWithHeaderProps = {
  className?: string;
  title: string,
  subtitle?: string;
  bodyClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const BoxWithHeader = ({
  className,
  title,
  children,
  subtitle,
  bodyClassName,
  ...props
}: BoxWithHeaderProps) => {
  return (
    <div className={twMerge("w-[220px] border border-border-default rounded-lg ", className)} {...props}>
      <div className={twMerge("w-[220px] p-4 bg-white-100 flex justify-start flex-col text break-words")}>
        <p className="font-bold">{title}</p>
        {subtitle ? <p className="text-xs italic">{subtitle}</p> : null}
      </div>
      <div className={twMerge("p-4 flex flex-col gap-4 w-[220px] overflow-y-auto", bodyClassName)}>
        {children}
      </div>
    </div>
  );
};

export default BoxWithHeader;
