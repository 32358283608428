import { BillingEventData } from "@/common/types/billing/event.type";
import { formatCurrency } from "@/common/utils/formatters";
import { useState } from "react";
import TooltipPattern from "@/common/components/TooltipPattern";
import { X } from "lucide-react";
import Table from "@/common/components/Table";
import { twMerge } from "tailwind-merge";
import { transformToNumber } from "@/common/utils/transformers";
import { useCompanyFee } from "@/common/hooks/queries/useCompanyEvents";
import { CompanyFeeTransformer } from "@/common/services/companyFeeTransformer";
import { companyFeeColumns } from "@/common/constants/companyFeeColumns.constant";

type TotalFeeProps = {
  data: BillingEventData;
};

const ClientEventTotalFee = ({ data: event }: TotalFeeProps)=> {
  const [tooltipEventId, setTooltipEventId] = useState('');
  const [hoveredElement, setHoveredElement] = useState<HTMLElement | null>(null);
  const { data: detailedFee, isFetching: detailedFeeIsFetching } = useCompanyFee(tooltipEventId);
  const totalFee = transformToNumber({ value: event.fee_total, invert: true });
  const hasTotalFee = totalFee !== 0;

  const handleMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
    if (tooltipEventId !== event.id && hasTotalFee) {
      setTooltipEventId(event.id);
      setHoveredElement(e.currentTarget as HTMLElement);
    }
  };

  const handleCloseTooltip = () => {
    if(hasTotalFee){
      setHoveredElement(null);
      setTooltipEventId('');
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleCloseTooltip}>
      <div className={twMerge("cursor-default py-4", totalFee > 0 ? 'text-valid' : totalFee < 0 ? 'text-danger-soft' : '')}>
        {formatCurrency(Number(totalFee))}
      </div>

      {tooltipEventId === event.id && hasTotalFee && (
        <TooltipPattern target={hoveredElement} expectedWidth={350} expectedHeight={250}>
          <div className="bg-white-200 rounded-lg overflow-hidden border-gray-300 shadow-lg">
            <div className="bg-[#5A5D5F] flex items-center justify-between gap-2 p-6">
              <p className="text-[18px]">Detalhamento de tarifas</p>

              <div className="cursor-pointer" onClick={handleCloseTooltip}>
                <X className="h-6 w-6" color="red" />
              </div>
            </div>

            <div className="bg-[#2c2c2c]">
              <div className="bg-[#2c2c2c] max-h-[217px] overflow-y-auto pr-3">
                {<Table
                  className="rounded-none"
                  columns={companyFeeColumns}
                  rows={CompanyFeeTransformer.tableTransformer()(detailedFee?.fees) || []}
                  rowEmptyValue="--"
                  page={1}
                  totalPages={1}
                  isLoadingData={detailedFeeIsFetching}
                />}
              </div>
            </div>
          </div>
        </TooltipPattern>
      )}
    </div>
  );
};

export default ClientEventTotalFee;
