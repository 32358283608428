import { DocumentFileList } from '@/common/components/DocumentFileList'
import { checkIsAcceptedFile } from '@/common/utils/fileValidator'
import { UploadIcon } from 'lucide-react'
import { BaseSyntheticEvent, FormEvent } from 'react'
import { ArrayPath, Control, FieldArrayWithId, FieldValues, useFieldArray } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

export interface MultipleDocumentUploaderProps<T extends FieldValues> extends React.HTMLAttributes<HTMLInputElement> {
  label?: string
  name?: string
  title?: string
  uploadBlob: (data: { file: File; filename: string }) => Promise<any>
  accept?: string
  className?: string
  control: Control<T, any>
  disabled?: boolean
  fieldName: ArrayPath<T>
  submit?: (e?: BaseSyntheticEvent) => Promise<void>
}

interface UploadedFile {
  fileName: string
  currentFileUrl: string
}

export const MultipleDocumentUploader = <T extends FieldValues>({
  label,
  name,
  title = 'Anexe um arquivo',
  className,
  uploadBlob,
  accept = '',
  control,
  fieldName,
  submit,
  ...props
}: MultipleDocumentUploaderProps<T>) => {
  const {
    fields: files,
    append: appendFile,
    remove: removeFile,
  } = useFieldArray({
    control,
    name: fieldName,
  })

  const handleDocumentsChange = async (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement & { files: FileList }

    const files = target.files.length ? Array.from(target.files)?.filter((file) => checkIsAcceptedFile(file, accept)) : []

    if (!files.length) {
      return
    }

    for (const file of files) {
      const data = await uploadBlob({ file, filename: file.name })

      if (data?.data?.data?.id) {
        appendFile({
          id: data.data.data.id,
          file,
          fileName: file.name,
          currentFileUrl: URL.createObjectURL(file),
        } as unknown as FieldArrayWithId<T, ArrayPath<T>>)

        submit && submit()
      }
    }
    target.value = ''
  }

  const handleRemoveFile = (currentIndex: number) => {
    if (files.length > 0) {
      removeFile(currentIndex)
    }
  }

  return (
    <div className="flex flex-wrap gap-4">
      {files.length ? (
        <DocumentFileList
          files={files.map((file) => file as unknown as UploadedFile)}
          handleRemoveFile={handleRemoveFile}
          className={className}
        />
      ) : null}
      <div className="flex flex-col items-start">
        {label && <p className="text-xs mb-1">{label}</p>}
        <label
          className={twMerge(
            'flex flex-col justify-center border border-neutral-3 w-[180px] h-[100px] gap-2 items-center bg-neutral-1 cursor-pointer rounded-lg',
            className,
          )}
          htmlFor="file_input"
        >
          <p className="text-xs text-center">{title}</p>
          <UploadIcon strokeWidth={1} />
          <p className="text-xs italic">Ou arraste até aqui</p>
        </label>

        <input
          type="file"
          name={name}
          accept={accept}
          className="hidden"
          id="file_input"
          multiple
          onChange={handleDocumentsChange}
          {...props}
        />
      </div>
    </div>
  )
}
