import { PaymentModalityType } from '@/common/types/companies/companies.type'
import { GovernmentAllocationDetails } from '@/features/governmentAllocations/components/GovernmentAllocationDetails'
import { GovernmentAllocationMonthDetails } from '@/features/governmentAllocations/components/GovernmentAllocationMonthDetails'
import { governmentAllocationColumns } from '@/features/governmentAllocations/constants/governmentAllocationColumns.constant'
import { GovernmentAllocationData } from '@/features/governmentAllocations/types/governmentAllocation.type'
import { createElement, ReactElement } from 'react'

export class GovernmentAllocationTransformer {
  static tableTransformer({
    governmentAllocations,
    paymentModality,
    year,
  }: {
    governmentAllocations: GovernmentAllocationData[]
    paymentModality?: PaymentModalityType
    year: number
  }) {
    return () => {
      return governmentAllocations.map((governmentAllocation) => {
        const object: Record<string, ReactElement> = {}

        governmentAllocation.applicable_end_date = governmentAllocation.applicable_end_date.replace(/-/g, '/')
        governmentAllocation.applicable_start_date = governmentAllocation.applicable_start_date.replace(/-/g, '/')

        governmentAllocationColumns().forEach((column, index) => {
          object[column.value] = createElement(GovernmentAllocationMonthDetails, {
            governmentAllocation,
            year,
            month: index - 1,
          })
        })

        object.governmentAllocation = createElement(GovernmentAllocationDetails, { governmentAllocation , paymentModality })

        return object
      })
    }
  }
}
