import Button from '@/common/components/Button'
import { twMerge } from 'tailwind-merge'

interface UnknownErrorMessageProps {
  refetchFunction: VoidFunction
  className?: string
}

export const UnknownErrorMessage = ({ refetchFunction, className }: UnknownErrorMessageProps) => {
  return (
    <div className={twMerge('flex w-full flex-col items-center gap-3 h-screen overflow-auto', className)}>
      <div className="mt-auto">
        <img src="/cloud.gif" className="shrink-0 w-[305px]" />
      </div>
      <div className="flex flex-col items-center -mt-16 mb-auto p-8">
        <p className="text-sm font-bold">Ops, algo deu errado!</p>
        <p className="text-xs mt-2">Não conseguimos carregar os dados. Por favor, tente novamente.</p>
        <Button onClick={refetchFunction} className="mt-4">
          Tentar novamente
        </Button>
      </div>
    </div>
  )
}
