import Box from "@/common/components/Box";
import Button from "@/common/components/Button";
import { ShowHeader } from "@/common/components/ShowHeader";
import { useCompanyBranch } from "@/common/hooks/queries/useCompanyBranch";
import { useNavigate, useParams } from "react-router-dom";
import { BranchInformation } from "../../components/show/BranchInformation";
import { GeneralInformation } from "../../components/show/GeneralInformation";
import { MasterClientUser } from "../../components/show/MasterClientUser";
import { ConsumptionRestriction } from "@/features/company/components/Profile/ConsumptionRestriction";
import { PeriodRestriction } from "@/features/company/components/Profile/PeriodRestriction";
import { CreditConfiguration } from "@/features/company/components/Profile/CreditConfiguration";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";
import { BriefcaseBusinessIcon } from "lucide-react";
import { ErrorMessageWrapper } from "@/common/components/ErrorMessageWrapper";

const CompanyBranchesShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: branchData,
    isPending: branchIsPending,
    error: branchError = {} as any,
    refetch: refetchBranch,
  } = useCompanyBranch(id || '');

  return (
    <>
      <ShowHeader
        backTo="/company-branches"
        LogoPlaceholder={
          <ImageWithPlaceholder
            className="size-8"
            iconClassName="size-6"
            placeholderIcon={BriefcaseBusinessIcon}
            url={branchData?.general_information?.company_logo?.url}
            alt={branchData?.general_information?.fantasy_name}
          />
        }
        displayName={branchData?.display_name}
        buttons={(
          <Button
            type="button"
            className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]"
            onClick={() => navigate(`/company-branches/${id}/edit`)}
            disabled={branchIsPending || branchError?.status === 403}
          >
            Editar
          </Button>
        )}
      />

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={branchError?.status}
        refetchFunction={refetchBranch}
      >
        <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto h-[calc(100vh-128px)]">
          <p className="text-lg font-bold">
            Dados da filial
          </p>

          <BranchInformation
            displayName={branchData?.display_name}
            parentId={branchData?.parent_id}
          />

          <GeneralInformation
            generalInformation={branchData?.general_information}
          />

          <MasterClientUser
            masterClientUser={branchData?.master_client_user}
          />

          <CreditConfiguration
            productCategoryGrantedCredits={branchData?.product_category_granted_credits}
          />

          <ConsumptionRestriction
            restriction={branchData?.restriction}
          />

          {Number(branchData?.restriction.period_restrictions?.length) > 0 && (
            <PeriodRestriction
              periodRestrictions={branchData?.restriction.period_restrictions}
            />
          )}
        </Box>
      </ErrorMessageWrapper>
    </>
  );
};

export default CompanyBranchesShow;
