import usePrivateAxios from '@/common/api/usePrivateAxios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type UseGovernmentAllocationsProps<T> = {
  filters: {
    companyId: string
    branchId?: string
    startDate?: string
    endDate?: string
  }
  mapper?: (data: any) => T
}

export const useGovernmentAllocations = <T,>({
  filters: { companyId, branchId, startDate, endDate },
  mapper = Mapper.default<T>(),
}: UseGovernmentAllocationsProps<T>) => {
  const privateAxios = usePrivateAxios()

  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    queryKey: ['governmentAllocations', companyId, branchId, startDate, endDate],
    enabled: !!companyId && !!branchId,
    queryFn: () => {
      return privateAxios.get(`/billing/government_allocations`, {
        params: {
          'q[company_id_eq]': companyId,
          'q[branch_id_eq]': branchId,
          'q[applicable_start_date_gteq]': startDate,
          'q[applicable_end_date_lteq]': endDate,
        },
      })
    },
    select: (data: { data: { data: any[]; meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, isPending, ...rest }
}
