import { forwardRef } from "react";
import { cva, VariantProps } from 'class-variance-authority';
import { twMerge } from "tailwind-merge";
import { cn } from "../../utils/cn";

const textFieldVariants = cva(
  'p-4 h-[52px] w-full text-sm rounded-md border bg-neutral-op-8 outline-none border border-transparent',
  {
    variants: {
      variant: {
        default: 'text-white-800',
        disabled: 'text-white-300',
        error: 'text-white-800 border-danger'
      }
    },
    defaultVariants: {
      variant: 'default',
    }
  }
);

export interface TextFieldProps extends React.HTMLAttributes<HTMLInputElement>, VariantProps<typeof textFieldVariants> {
  label?: string,
  markAsRequired?: boolean,
  type?: string,
  disabled?: boolean,
  maxLength?: number,
  RightIcon?: JSX.Element;
  LeftIcon?: JSX.Element;
  labelClass?: string;
  placeholder?: string;
  errorMessage?: string;
  value?: string | null;
  onIconClick?: React.MouseEventHandler<HTMLDivElement>,
  isLoading?: boolean;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({

  label,
  markAsRequired,
  variant,
  className,
  disabled = false,
  maxLength = 64,
  placeholder,
  RightIcon,
  labelClass = "",
  LeftIcon,
  errorMessage,
  value,
  onIconClick,
  type = 'text',
  isLoading,
  ...props
}, ref) => {

  return (
    <div className={className}>
      <label className={`${labelClass} relative flex flex-col items-start justify-center`}>
        {label ? <p className={twMerge('text-xs', disabled ? '' : '', 'mb-1')}>{label}{markAsRequired && <span className="text-danger-soft"> *</span>}</p> : null}
        {
          RightIcon
            ?
            <div
              className={twMerge('absolute right-0 bottom-0 -translate-x-1/2 -translate-y-[calc(50%+4px)]', onIconClick && 'cursor-pointer')}
              onClick={onIconClick}
            >
              {RightIcon}
            </div>
            : null
        }
        {
          isLoading &&
          <div
            className={twMerge('absolute right-0 bottom-0 -translate-x-1/2 -translate-y-[calc(50%+4px)]', onIconClick && 'cursor-pointer')}
          >
            <div className="w-4 h-4 border-4 border-dashed rounded-full animate-spin border-blue-800 mb-1 mr-1" />
          </div>
        }
        {
          LeftIcon
            ?
            <div
              className={twMerge('absolute left-0 bottom-0 translate-x-1/2 -translate-y-[calc(50%+4px)]', onIconClick && 'cursor-pointer')}
              onClick={onIconClick}
            >
              {LeftIcon}
            </div>
            : null
        }
        <input
          ref={ref}
          type={type}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value as string | undefined}
          className={cn(textFieldVariants({ variant: disabled ? 'disabled' : variant }), LeftIcon && 'pl-10')}
          {...props}
        />
      </label>
      {errorMessage?.length && <span className="text-danger-soft text-sm">{errorMessage}</span>}
    </div>
  );
});

export default TextField;
