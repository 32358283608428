import { privateAxios } from "@/common/api/axios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type UseEstablishmentCompaniesProps<T> = {
  filters?: {
    search?: string;
    productCategoryId?: string;
    stateId?: string;
    cityId?: string;
  };
  mapper?: (data: any) => T,
};

export const useEstablishmentCompanies = <T,>({
  filters: { search = '', productCategoryId, stateId, cityId } = {},
  mapper = Mapper.default<T>(),
}: UseEstablishmentCompaniesProps<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    queryKey: ['establishmentCompanies', search, productCategoryId, cityId, stateId],
    queryFn: () => {
      return privateAxios.get('/establishment/companies', {
        params: {
          search,
          'q[product_category_id_eq]': productCategoryId,
          'q[city_id_eq]': cityId,
          'q[state_id_eq]': stateId,
        }
      });
    },
    select: (data: { data: { data: any[], meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, isPending, ...rest };
};
