import { AnnualFilter } from '@/common/components/AnnualFilter'
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper'
import FilterCheckbox from '@/common/components/FilterCheckbox'
import ListHeader from '@/common/components/ListHeader'
import Select from '@/common/components/Select'
import Table from '@/common/components/Table'
import { useCompanyBranches } from '@/common/hooks/queries/useCompanyBranches'
import useDebounce from '@/common/hooks/useDebounce'
import { useFilterParams } from '@/common/hooks/useFilterParams'
import { Mapper } from '@/common/services/mapper'
import { CompanyBranchData } from '@/common/types/companyBranches.type'
import { Option } from '@/common/types/option'
import { useMineCompany } from '@/features/company/hooks/useMineCompany'
import { CreateGovernmentAllocationModal } from '@/features/governmentAllocations/components/CreateGovernmentAllocationModal'
import { governmentAllocationColumns } from '@/features/governmentAllocations/constants/governmentAllocationColumns.constant'
import { useGovernmentAllocations } from '@/features/governmentAllocations/hooks/useGovernmentAllocations'
import { GovernmentAllocationTransformer } from '@/features/governmentAllocations/services/governmentAllocationsTransformer'
import { GovernmentAllocationData } from '@/features/governmentAllocations/types/governmentAllocation.type'
import { format } from 'date-fns'
import { FileStackIcon } from 'lucide-react'

const List = () => {
  const { data: company } = useMineCompany();

  const paymentModality = company?.current_contract?.payment_modality

  const { filterParams, setFilterParams } = useFilterParams()

  const [searchBranch, setSearchBranch] = useDebounce('')

  const {
    data: governmentAllocations,
    isFetching: governmentAllocationsIsFetching,
    error: governmentAllocationsError = {} as any,
    refetch: refetchGovernmentAllocations,
  } =
    useGovernmentAllocations<GovernmentAllocationData[]>({
      filters: {
        companyId: company?.id || '',
        branchId: filterParams.branch?.value,
        startDate: format(new Date(filterParams.year || new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'),
        endDate: format(new Date(filterParams.year || new Date().getFullYear(), 11, 31), 'yyyy-MM-dd'),
      },
    })

  const { data: companyBranchOptions } = useCompanyBranches({
    filters: { search: searchBranch },
    mapper: Mapper.mapToOptions<CompanyBranchData>({
      labelFieldName: 'display_name',
      valueFieldName: 'id',
    }),
  })

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <ListHeader
          Icon={<FileStackIcon className="size-10" strokeWidth={1.5} />}
          title={paymentModality === 'pre_paid' ? 'Empenhos' : 'Cotas Estimativas'}
        />
      </div>
      <ErrorMessageWrapper
        className="h-[calc(100vh-128px)]"
        statusCode={governmentAllocationsError?.status}
        refetchFunction={refetchGovernmentAllocations}
      >
        <div className="flex flex-col gap-6">
          <div className="flex justify-between">
            <div className="flex items-center gap-x-2">
              <AnnualFilter setFilterParams={setFilterParams} year={filterParams.year || new Date().getFullYear()} />

              <Select
                placeholder="Filial"
                className="text-xs rounded-xl bg-white-100 shadow-[0px_0px_8px_4px_rgba(0,_0,_0,_0.2)] z-20"
                badge
                value={filterParams.branch?.value ? filterParams.branch : null}
                onChange={(e) => setFilterParams((prev) => ({ ...prev, branch: (e as Option).value ? e : undefined }))}
                options={companyBranchOptions || []}
              />

              <FilterCheckbox
                isChecked={filterParams.drafts === 'true'}
                onChange={(value: any) => {
                  setFilterParams((prev) => ({
                    ...prev,
                    drafts: value,
                  }))
                }}
                label="Rascunhos (Não implementado)"
                id="filter-checkbox"
                name="filter-checkbox"
              />
            </div>

            <CreateGovernmentAllocationModal paymentModality={paymentModality} />
          </div>

          {!!filterParams.branch?.value ? (
            <Table
              className="max-h-[calc(100vh-192px)] shadow-[0px_0px_8px_4px_rgba(0,_0,_0,_0.2)]"
              headerCellClassName="min-w-[60px]"
              isLoadingData={governmentAllocationsIsFetching}
              columns={governmentAllocationColumns(paymentModality)}
              rows={GovernmentAllocationTransformer.tableTransformer({
                governmentAllocations,
                year: filterParams.year || new Date().getFullYear(),
                paymentModality,
              })()}
              page={1}
              rowEmptyValue=""
              totalPages={1}
            />
          ) : (
            <div className="flex flex-col items-center pt-20 bg-[#FFFFFF1A] h-[calc(100vh-256px)] overflow-y-auto shadow-[0px_0px_8px_4px_rgba(0,_0,_0,_0.2)]">
              <p>
                Selecione uma filial para visualizar{' '}
                {paymentModality === 'pre_paid' ? 'os empenhos' : 'as cotas estimativas'}.
              </p>

              <img src="/search.gif" className="w-full max-w-80 shrink-0" />

              <Select
                placeholder="Filial"
                className="w-80"
                onInputChange={setSearchBranch}
                onChange={(e) => setFilterParams((prev) => ({ ...prev, branch: (e as Option).value ? e : undefined }))}
                options={companyBranchOptions || []}
              />
            </div>
          )}
        </div>
      </ErrorMessageWrapper>
    </div>
  )
}

export default List
