import { privateAxios } from "@/common/api/axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

type UseBillingFiscalDocumentTotalizerType = ({ }: { id?: string; }) => UseQueryResult<any, unknown>;

const useBillingFiscalDocumentGroupTotalizer: UseBillingFiscalDocumentTotalizerType = ({ id }) => {
  return useQuery<any, unknown, any>({
    enabled: !!id,
    queryKey: ['billingFiscalDocumentGroupTotalizer', id],
    queryFn: () => {
      return privateAxios.get(`/billing/fiscal_document_groups/${id}/totalizer`);
    },
    select: (data) => data?.data?.data,
  });
};

export default useBillingFiscalDocumentGroupTotalizer;
