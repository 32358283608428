import { ForbiddenErrorMessage } from '@/common/components/ForbiddenErrorMessage'
import { UnknownErrorMessage } from '@/common/components/UnknownErrorMessage'
import { ReactNode } from 'react'

interface ErrorMessageWrapperProps {
  children: ReactNode
  className?: string
  refetchFunction: VoidFunction
  statusCode?: number
}

export const ErrorMessageWrapper = ({ children, className, refetchFunction, statusCode }: ErrorMessageWrapperProps) => {
  if (statusCode === 403) return <ForbiddenErrorMessage className={className} />

  if (statusCode === undefined || (statusCode >= 200 && statusCode < 300)) return children

  return <UnknownErrorMessage refetchFunction={refetchFunction} className={className} />
}
