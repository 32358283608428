import usePrivateAxios from '@/common/api/usePrivateAxios'
import { unFormatCurrency } from '@/common/utils/unFormatters'
import { CreateGovernmentAllocationValidationSchema } from '@/features/governmentAllocations/schemas/createGovernmentAllocation.schema'
import { useMutation } from '@tanstack/react-query'

type CreateGovernmentAllocationProps = {
  onSuccess: () => void
  onError: (error: any) => void
}

export const useCreateGovernmentAllocation = ({ onSuccess, onError }: CreateGovernmentAllocationProps) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['createGovernmentAllocation'],
    mutationFn: async ({ form, file }: { form: CreateGovernmentAllocationValidationSchema; file?: Blob }) => {
      const government_allocation = {
        branch_id: form.branch_id.value,
        start_of_document_validity: form.start_of_document_validity,
        end_of_document_validity: form.end_of_document_validity,
        office_number: form.office_number,
        total_value: unFormatCurrency(form.total_value),
        force_post_paid: false,
        file,
      }

      return privateAxios.postForm('/billing/government_allocations', {
        government_allocation,
      })
    },
    onSuccess: onSuccess,
    onError: onError,
  })
}
