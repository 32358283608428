import { twMerge } from 'tailwind-merge'

interface ForbiddenErrorMessageProps {
  className?: string
}

export const ForbiddenErrorMessage = ({ className }: ForbiddenErrorMessageProps) => {
  return (
    <div className={twMerge('flex w-full flex-col items-center gap-3 h-screen overflow-auto', className)}>
      <div className="mt-auto">
        <img src="/cloud.gif" className="shrink-0 w-[305px]" />
      </div>
      <div className="flex flex-col items-center -mt-16 mb-auto p-8">
        <p className="text-sm font-bold">Você não possui permissão para acessar este recurso.</p>
        <p className="text-xs mt-2">Entre em contato com seu gestor para mais informações.</p>
      </div>
    </div>
  )
}
