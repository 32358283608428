import { formatCurrency, formatDecimal, formatUnit } from "@/common/utils/formatters";

export class ProductsSummaryTransformer {
  static tableTransformer() {
    return (products: any[]) => {
      return products.map((product) => {
        return {
          ...product,
          total_consumptions_count: Number(product.total_consumptions_count || 0),
          total_quantity: formatUnit(formatDecimal(product.total_quantity), product.unit_for_quantity),
          total_amount: formatCurrency(Number(product.total_amount) || 0),
        };
      });
    };
  }
}
