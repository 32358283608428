import Button from '@/common/components/Button'
import { useGoBack } from '@/common/hooks/useGoBack'
import { useMineCompany } from '@/features/company/hooks/useMineCompany'
import { useGovernmentAllocationEntry } from '@/features/governmentAllocations/hooks/useGovernmentAllocationEntry'
import { governmentAllocationFormStateOptions } from '@/features/governmentAllocations/pages/Update/constants/governmentAllocationFormStateOptions.constant'
import { ChevronLeftIcon, ChevronsRightIcon } from 'lucide-react'
import { useParams } from 'react-router-dom'

const Update = () => {
  const { id } = useParams()
  const goBack = useGoBack()

  const { data: company } = useMineCompany()

  const { data: governmentAllocation, isLoading: governmentAllocationIsLoading } = useGovernmentAllocationEntry(
    id || '',
  )

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-4 items-center">
          <Button variant="link-white" onClick={() => goBack({ fallback: '/government-allocations' })}>
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>

          <div className="flex items-center gap-1">
            <p className="text-white-400">{company?.matrix_branch?.general_information?.fantasy_name}</p>

            <ChevronsRightIcon className="text-white-400" />

            <p className="text-white-800">{governmentAllocation?.office_number}</p>
          </div>
        </div>
      </div>

      {!governmentAllocationIsLoading && governmentAllocation && id && (
        <>
          {governmentAllocationFormStateOptions
            .filter(({ value }) => value === governmentAllocation.status)
            .map(({ comp: Comp }) => {
              return <Comp key={governmentAllocation.id} governmentAllocation={governmentAllocation} />
            })}
        </>
      )}
    </div>
  )
}

export default Update
