import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogConfirmFooter,
} from "@/common/components/ui/dialog"

export interface ModalProps {
  Trigger?: JSX.Element
  triggerDisabled?: boolean
  className?: string;
  title?: JSX.Element | string
  titleClass?: string
  subtitle?: JSX.Element | string
  subtitleClassName?:string
  body?: JSX.Element
  footer?: string
  footerClassName?: string
  useCancellableFooter?: boolean
  confirmButtonText?: string
  confirmButtonClassName?: string
  confirmButtonIsLoading?: boolean
  cancelButtonText?: string
  cancelButtonClassName?: string
  disabled?: boolean
  onConfirm?: () => void
  onCancel?: () => void
  isOpen?: boolean
  setIsOpen?: (value: boolean) => void
  showClose?: boolean
}

const Modal = ({
  Trigger,
  triggerDisabled,
  className,
  title,
  titleClass,
  subtitle,
  body,
  footer,
  footerClassName,
  useCancellableFooter,
  confirmButtonText,
  confirmButtonClassName,
  cancelButtonText,
  cancelButtonClassName,
  disabled,
  onConfirm,
  onCancel,
  isOpen,
  subtitleClassName = "",
  setIsOpen,
  showClose,
}: ModalProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen} >
      {Trigger && <DialogTrigger disabled={triggerDisabled}>{Trigger}</DialogTrigger>}
      <DialogContent className={className}>
        {title
          ? <DialogHeader showClose={showClose}>
              <DialogTitle className={titleClass}>{title}</DialogTitle>
              {subtitle
                ? <p className={`${subtitleClassName} text-sm`}>{subtitle}</p>
                : null
              }
            </DialogHeader>
          : null
        }
        {body
          ? <DialogDescription>
              {body}
            </DialogDescription>
          : null
        }
        {useCancellableFooter
          ? <DialogConfirmFooter
              cancelButton={{
                children: cancelButtonText || 'Cancelar',
                className: cancelButtonClassName,
                disabled: disabled,
                onClick: onCancel,
              }}
              confirmButton={{
                children: confirmButtonText || 'Confirmar',
                className: confirmButtonClassName,
                disabled: disabled,
                onClick: onConfirm,

              }}
            />
          :  footer
            ? <DialogFooter className={footerClassName} onClick={onConfirm}>
                {footer}
              </DialogFooter>
            : null
        }
      </DialogContent>
    </Dialog>
  )
}

export default Modal
