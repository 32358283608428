import useLogRequest from "@/common/hooks/queries/useLogRequest";
import { useParams } from "react-router-dom";
import RequestResume from "./components/RequestResume";
import RequestChanges from "./components/RequestChanges";
import RequestResumeLoading from "./components/RequestResumeLoading";
import { ErrorMessageWrapper } from "@/common/components/ErrorMessageWrapper";

const LogRequestShow = () => {
  const { requestId } = useParams();

  const {
    data: request,
    isPending: requestIsPending,
    error: requestError = {} as any,
    refetch: refetchRequest,
  } = useLogRequest(requestId);

  if (requestIsPending) return <RequestResumeLoading />;

  return (
    <ErrorMessageWrapper
      className="max-h-[calc(100vh-128px)]"
      statusCode={requestError?.status}
      refetchFunction={refetchRequest}
    >
      <div className="flex flex-col gap-6 h-full">
        {request && <RequestResume request={request} />}

        {request?.log_changes && request?.log_changes?.length > 0 && <RequestChanges request={request} />}
      </div>
    </ErrorMessageWrapper>
  );
};

export default LogRequestShow;