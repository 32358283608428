import Accordion from '@/common/components/Accordion'
import Box from '@/common/components/Box'
import Button from '@/common/components/Button'
import { Checklist } from '@/common/components/Checklist'
import { CurrencyTextField } from '@/common/components/CurrencyTextField'
import { FileUploader } from '@/common/components/FileUploader'
import MaskedTextField from '@/common/components/MaskedTextField'
import { RequestedReview } from '@/common/components/RequestedReview'
import SelectController from '@/common/components/SelectController'
import TextField from '@/common/components/TextField'
import { useProductCategories } from '@/common/hooks/queries/useProductCategories'
import useDebounce from '@/common/hooks/useDebounce'
import { Mapper } from '@/common/services/mapper'
import { ErrorResponse } from '@/common/types/errorResponse'
import { ProductCategoryData } from '@/common/types/productCategory.type'
import { dateTransformer } from '@/common/utils/dateTransformer'
import { formatCurrency, formatDateRange } from '@/common/utils/formatters'
import { getFilenameFromUrl } from '@/common/utils/getFilenameFromUrl'
import { handleServerError } from '@/common/utils/handleServerError'
import { useMineCompany } from '@/features/company/hooks/useMineCompany'
import { useAdvanceGovernmentAllocationEntry } from '@/features/governmentAllocations/hooks/useAdvanceGovernmentAllocationEntry'
import { useUpdateGovernmentAllocationEntry } from '@/features/governmentAllocations/hooks/useUpdateGovernmentAllocationEntry'
import { useUpdateGovernmentAllocationEntryFile } from '@/features/governmentAllocations/hooks/useUpdateGovernmentAllocationEntryFile'
import {
  GovernmentAllocationEntryUpdateValidationSchema,
  updateSchema,
} from '@/features/governmentAllocations/schemas/update.schema'
import { GovernmentAllocationEntryData } from '@/features/governmentAllocations/types/governmentAllocationEntry.type'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { CalendarDaysIcon, SearchIcon } from 'lucide-react'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

export const GovernmentAllocationEntryCreatedForm = ({
  governmentAllocation,
}: {
  governmentAllocation: GovernmentAllocationEntryData
}) => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const passedEveryRequirement = governmentAllocation?.requirement_list_summary?.steps?.every((step) => step.passed)

  const [file, setFile] = useState<Blob>()
  const [fileName, setFileName] = useState('')
  const [searchProductCategories, setSearchProductCategories] = useDebounce('')
  const [baseError, setBaseError] = useState('')

  const { data: productCategoryOptions } = useProductCategories({
    filters: { search: searchProductCategories },
    mapper: Mapper.mapToOptions<ProductCategoryData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  })

  const useFormMethods = useForm<GovernmentAllocationEntryUpdateValidationSchema>({
    resolver: zodResolver(updateSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormMethods

  const { data: company, isFetching: governmentAllocationIsFetching } = useMineCompany()

  const onSuccess = () => {
    setBaseError('')
    queryClient.invalidateQueries({ queryKey: ['governmentAllocation', id] })
  }

  const redirectOnSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['governmentAllocations'] })
    queryClient.invalidateQueries({ queryKey: ['governmentAllocation', id] })
    navigate('/government-allocations')
  }

  const onError = (error: ErrorResponse) => {
    handleServerError({ error, setBaseError, setError })
  }

  const { mutate: updateGovernmentAllocationEntry } = useUpdateGovernmentAllocationEntry({
    governmentAllocationId: id,
    onSuccess,
    onError,
  })

  const { mutate: uploadGovernmentAllocationFile, isPending: uploadGovernmentAllocationFileIsPending } =
    useUpdateGovernmentAllocationEntryFile({
      governmentAllocationId: id,
      onSuccess,
      onError,
    })

  const { mutate: advanceGovernmentAllocationEntry, isPending: advanceGovernmentAllocationEntryIsPending } =
    useAdvanceGovernmentAllocationEntry({
      id,
      onSuccess: redirectOnSuccess,
      onError,
    })

  const onSubmit: SubmitHandler<GovernmentAllocationEntryUpdateValidationSchema> = (data) => {
    clearErrors()
    setBaseError('')
    updateGovernmentAllocationEntry(data)
  }

  const submit = handleSubmit(onSubmit)

  const submittableOnBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>,
    onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
  ) => {
    if (e.target.value === '') {
      return
    }
    onBlur(e)
    submit()
  }

  return (
    <Box className="flex gap-6 items-start h-[calc(100vh-130px)] shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]">
      <div className="w-full flex flex-col gap-6 h-[calc(100vh-178px)] overflow-y-auto">
        {baseError && <p className="text-sm text-danger-soft">{baseError}</p>}

        <p className="text-lg font-bold">
          Detalhes {governmentAllocation?.government_allocation?.kind === 'pre_paid' ? 'do Empenho' : 'da Cota'}
        </p>

        {governmentAllocation?.last_review?.state === 'disapproved' && (
          <RequestedReview description={governmentAllocation?.last_review?.description} />
        )}

        <div>
          <p className="text-sm font-light italic">Valor global do contrato</p>
          <p className="text-lg font-bold">{formatCurrency(Number(company?.current_contract?.granted_credit || 0))}</p>
        </div>

        {governmentAllocation?.government_allocation?.kind === 'pre_paid' ? (
          <div className="flex gap-x-2">
            <Controller
              control={control}
              name="office_number"
              defaultValue={governmentAllocation?.office_number}
              disabled={advanceGovernmentAllocationEntryIsPending}
              render={({ field: { onBlur, ...rest } }) => (
                <TextField
                  label="Número do ofício do Empenho"
                  className="mt-5"
                  {...rest}
                  errorMessage={errors.office_number?.message}
                  onBlur={(e) => submittableOnBlur?.(e, onBlur)}
                />
              )}
            />

            <div className="space-y-2">
              <p className="text-[11px] leading-3">Período de competência</p>

              <div className="flex items-start gap-x-2">
                <Controller
                  control={control}
                  name="government_allocation_attributes.start_of_document_validity"
                  defaultValue={
                    governmentAllocation?.government_allocation?.start_of_document_validity
                      ? dateTransformer(governmentAllocation?.government_allocation?.start_of_document_validity)
                      : undefined
                  }
                  disabled={advanceGovernmentAllocationEntryIsPending}
                  render={({ field: { onBlur, ...rest } }) => (
                    <MaskedTextField
                      label="Início"
                      mask="00/00/0000"
                      LeftIcon={<CalendarDaysIcon strokeWidth={1} />}
                      {...rest}
                      errorMessage={errors.government_allocation_attributes?.start_of_document_validity?.message}
                      onBlur={(e) => submittableOnBlur?.(e, onBlur)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="government_allocation_attributes.end_of_document_validity"
                  defaultValue={
                    governmentAllocation?.government_allocation?.end_of_document_validity
                      ? dateTransformer(governmentAllocation?.government_allocation?.end_of_document_validity)
                      : undefined
                  }
                  disabled={advanceGovernmentAllocationEntryIsPending}
                  render={({ field: { onBlur, ...rest } }) => (
                    <MaskedTextField
                      label="Fim"
                      mask="00/00/0000"
                      LeftIcon={<CalendarDaysIcon strokeWidth={1} />}
                      {...rest}
                      errorMessage={errors.government_allocation_attributes?.end_of_document_validity?.message}
                      onBlur={(e) => submittableOnBlur?.(e, onBlur)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <p className="text-sm font-light italic">N da cota estimativa</p>
              <p className="text-lg font-bold">{governmentAllocation?.office_number}</p>
            </div>

            <div>
              <p className="text-sm font-light italic">Período de competência</p>
              <p className="text-lg font-bold">
                {governmentAllocation?.government_allocation?.start_of_document_validity &&
                  governmentAllocation?.government_allocation?.end_of_document_validity &&
                  formatDateRange(
                    new Date(governmentAllocation?.government_allocation?.start_of_document_validity),
                    new Date(governmentAllocation?.government_allocation?.end_of_document_validity),
                  )}
              </p>
            </div>
          </>
        )}

        <div>
          <p className="text-sm font-light italic">
            Tipo de controle{' '}
            {governmentAllocation?.government_allocation?.kind === 'pre_paid' ? 'de empenho' : 'da cota'} (Não
            implementado)
          </p>
        </div>

        {governmentAllocation?.government_allocation?.kind === 'pre_paid' && (
          <FileUploader
            name="file"
            accept="application/pdf, image/png, image/jpg, image/jpeg"
            title="Anexe o Empenho"
            fileName={
              fileName
                ? fileName
                : governmentAllocation?.file?.url
                ? getFilenameFromUrl(governmentAllocation?.file?.url)
                : undefined
            }
            currentFileUrl={
              file
                ? URL.createObjectURL(file)
                : governmentAllocation?.file?.url
                ? governmentAllocation?.file?.url
                : undefined
            }
            handleChange={(file) => {
              setFileName(file.name)
              setFile(file)
              uploadGovernmentAllocationFile(file)
            }}
            disabled={uploadGovernmentAllocationFileIsPending || advanceGovernmentAllocationEntryIsPending}
          />
        )}

        <Accordion
          className="py-1"
          head={
            <p>
              Detalhamento {governmentAllocation?.government_allocation?.kind === 'pre_paid' ? 'do Empenho' : 'da Cota'}
            </p>
          }
          body={
            <div className="flex flex-col gap-4">
              <Controller
                name="total_value"
                control={control}
                defaultValue={
                  governmentAllocation?.total_value
                    ? formatCurrency(Number(governmentAllocation?.total_value))
                    : undefined
                }
                disabled={advanceGovernmentAllocationEntryIsPending}
                render={({ field: { onBlur, ...rest } }) => (
                  <CurrencyTextField
                    label={`Valor total ${
                      governmentAllocation?.government_allocation?.kind === 'pre_paid' ? 'do empenho' : 'da cota'
                    }`}
                    markAsRequired
                    maxValue={999_999_999.99}
                    className="w-full"
                    {...rest}
                    onBlur={(e) => submittableOnBlur?.(e, onBlur)}
                    errorMessage={errors.total_value?.message}
                  />
                )}
              />

              <SelectController
                control={control}
                name="product_categories"
                placeholder="Pesquisar Serviços"
                labelIcon={<SearchIcon />}
                value={null}
                onInputChange={(value) => setSearchProductCategories(value)}
                options={productCategoryOptions || []}
                isDisabled={governmentAllocationIsFetching}
                isLoading={governmentAllocationIsFetching}
              />
            </div>
          }
        />
      </div>

      <Checklist
        requirement_list_summary={governmentAllocation?.requirement_list_summary}
        className="flex flex-col gap-2 justify-between h-[calc((100vh-276px)/2)]"
        Buttons={() => (
          <Button
            type="button"
            variant={passedEveryRequirement ? 'default' : 'outline-disabled'}
            disabled={!passedEveryRequirement && !advanceGovernmentAllocationEntryIsPending}
            onClick={() => advanceGovernmentAllocationEntry()}
            isLoading={advanceGovernmentAllocationEntryIsPending}
          >
            Enviar para análise
          </Button>
        )}
      />
    </Box>
  )
}
