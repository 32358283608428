import { twMerge } from 'tailwind-merge'

interface EmptyDataMessageProps {
  className?: string
}

export const EmptyDataMessage = ({ className }: EmptyDataMessageProps) => {
  return (
    <div className={twMerge('flex w-full flex-col items-center gap-3 h-screen overflow-auto', className)}>
      <div className="mt-auto">
        <img src="/search.gif" className="shrink-0 w-[305px]" />
      </div>
      <div className="-mt-16 mb-auto p-8">
        <p className="text-sm font-bold text-center mt-2">Parece que ainda não há registros aqui.</p>
      </div>
    </div>
  )
}
