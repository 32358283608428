export const checkIsImage = (file: File): boolean => {
  return file.type.match(/image\/*/) !== null;
};

export const checkIsPdf = (file: File): boolean => {
  return file.type.match('application/pdf') !== null;
};

export const checkIsAcceptedFile = (file: File, accept: string): boolean => {
  const acceptArray = accept.split(',').map((item) => item.trim());
  const fileExtension = file.name.split('.').pop();
  return acceptArray.includes(`.${fileExtension}`) || accept.includes(file.type);
}

export const checkIsXml = (file: File): boolean => {
  return file.type.match('text/xml') !== null;
}
