import Login from "./features/auth/pages/Login";
import companyDomain from "./features/auth/pages/CompanyDomain";
import RecoverCompanyUrl from "./features/auth/pages/RecoverCompanyUrl";
import UserList from './features/users/pages/List';
import UserCreate from './features/users/pages/Create';
import UserShow from './features/users/pages/Show';
import UserUpdate from './features/users/pages/Update';
import Building from "./common/Layout/building";
import CompanyDashboard from "./features/company/pages/Dashboard";
import CompanyProfile from "./features/company/pages/Profile";
import EquipmentsList from "./features/equipments/screens/List";
import CompanyContract from "./features/company/pages/Contract";
import EquipmentsCreate from "./features/equipments/screens/Create";
import EquipmentsShow from "./features/equipments/screens/Show";
import EquipmentsUpdate from "./features/equipments/screens/Update";
import BillingBillsList from "./features/billingBills/pages/List";
import BillingEventsList from "./features/billingEvents/pages/List";
import CompanyBranchesStructure from "./features/companyBranches/pages/Structure";
import CompanyBranchesCreate from "./features/companyBranches/pages/Create";
import CompanyBranchesShow from "./features/companyBranches/pages/Show";
import CompanyBranchesUpdate from "./features/companyBranches/pages/Update";
import EstablishmentsMap from "./features/establishments/pages/Map";
import MovementsTotalizerList from "./features/movementsTotalizer/pages/List";
import EquipmentsChangeHistoryShow from "./features/equipments/screens/ChangeHistoryShow";
import UserActionsHistoryShow from "./features/users/pages/ActionsHistoryShow";
import SupportList from "./features/support/pages/List";
import SupportCreate from "./features/support/pages/Create";
import SupportShow from "./features/support/pages/Show";
import GovernmentAllocationList from "./features/governmentAllocations/pages/List";
import GovernmentAllocationUpdate from "./features/governmentAllocations/pages/Update";
import ResetPassword from "./features/auth/pages/ResetPassword";
import BillingEventsWithFiscalDocumentRequirementList from "./features/billing/pages/EventsWithFiscalDocumentRequirement/List";
import BillingEventsWithFiscalDocumentRequirementShow from "./features/billing/pages/EventsWithFiscalDocumentRequirement/Show";
import BillingFiscalDocumentGroupsList from "./features/billing/pages/FiscalDocumentGroups/List";
import BillingFiscalDocumentGroupsShow from "./features/billing/pages/FiscalDocumentGroups/Show";

export const publicRoutes = [
  { path: '/', comp: companyDomain },
  { path: '/recover-company-domain', comp: RecoverCompanyUrl },
];

export const publicCompanyRoutes = [
  { path: '/login', comp: Login },
  { path: '/reset-password', comp: ResetPassword },
];

export const privateCompanyRoutes = [
  // general data
  { path: '/company', comp: CompanyDashboard },
  { path: '/company/mine', comp: CompanyProfile },
  { path: '/company/mine/contract', comp: CompanyContract },

  // company-branches
  { path: '/company-branches', comp: CompanyBranchesStructure },
  { path: '/company-branches/create', comp: CompanyBranchesCreate },
  { path: '/company-branches/:id/edit', comp: CompanyBranchesUpdate },
  { path: '/company-branches/:id/*', comp: CompanyBranchesShow },

  // finacial
  { path: '/billing-events', comp: BillingEventsList },
  { path: '/movements-totalizer', comp: MovementsTotalizerList },
  { path: '/billing-bills', comp: BillingBillsList },

  // establishment
  { path: '/establishments', comp: EstablishmentsMap },
  { path: '/establishments/:id/*', comp: Building },

  // establishment
  { path: '/equipments', comp: EquipmentsList },
  { path: '/equipments/create', comp: EquipmentsCreate },
  { path: '/equipments/:id/edit', comp: EquipmentsUpdate },
  { path: '/equipments/:id/changeHistory/:requestId', comp: EquipmentsChangeHistoryShow },
  { path: '/equipments/:id/*', comp: EquipmentsShow },

  // users
  { path: '/users', comp: UserList },
  { path: '/users/create', comp: UserCreate },
  { path: '/users/:id/edit', comp: UserUpdate },
  { path: '/users/:id/actionsHistory/:requestId', comp: UserActionsHistoryShow },
  { path: '/users/:id/*', comp: UserShow },

  // support
  { path: '/support', comp: SupportList },
  { path: '/support/create', comp: SupportCreate },
  { path: '/support/:id', comp: SupportShow },

  // government allocations
  { path: '/government-allocations', comp: GovernmentAllocationList },
  { path: '/government-allocations/:id/edit', comp: GovernmentAllocationUpdate },

  // events with fiscal document requirement
  { path: '/events-with-fiscal-document-requirement', comp: BillingEventsWithFiscalDocumentRequirementList },
  { path: '/events-with-fiscal-document-requirement/:id', comp: BillingEventsWithFiscalDocumentRequirementShow },

  // fiscal document groups
  { path: '/billing-fiscal-document-groups', comp: BillingFiscalDocumentGroupsList },
  { path: '/billing-fiscal-document-groups/:id', comp: BillingFiscalDocumentGroupsShow },
];
