export const MobileWarning = () => {
  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-between gap-4 p-8">
      <img src="/Logo.svg" />

      <div className="flex flex-col items-center gap-2">
        <p className="font-bold text-xl text-center">Plataforma não disponível em dispositivos móveis</p>
        <p className="font-light text-sm text-center italic">Acesse pelo computador ou notebook.</p>
      </div>

      <div className="flex flex-col items-center">
        <span className="text-[10px] italic font-light">Desenvolvido por</span>
        <img src="/Avantsoft.svg" />
      </div>
    </div>
  )
}
