import { PaymentModalityType } from '@/common/types/companies/companies.type'

export const governmentAllocationColumns = (companyPaymentModality?: PaymentModalityType) => [
  {
    label: companyPaymentModality === 'pre_paid' ? 'Empenho' : 'Cota',
    value: 'governmentAllocation',
    className: 'w-0',
  },
  { label: 'Jan', value: 'jan', className: 'w-[80px] p-0' },
  { label: 'Fev', value: 'feb', className: 'w-[80px] p-0' },
  { label: 'Mar', value: 'mar', className: 'w-[80px] p-0' },
  { label: 'Abr', value: 'apr', className: 'w-[80px] p-0' },
  { label: 'Mai', value: 'may', className: 'w-[80px] p-0' },
  { label: 'Jun', value: 'jun', className: 'w-[80px] p-0' },
  { label: 'Jul', value: 'jul', className: 'w-[80px] p-0' },
  { label: 'Ago', value: 'aug', className: 'w-[80px] p-0' },
  { label: 'Set', value: 'sep', className: 'w-[80px] p-0' },
  { label: 'Out', value: 'oct', className: 'w-[80px] p-0' },
  { label: 'Nov', value: 'nov', className: 'w-[80px] p-0' },
  { label: 'Dez', value: 'dec', className: 'w-[80px] p-0' },
]
