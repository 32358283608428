import usePrivateAxios from "@/common/api/usePrivateAxios";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

interface UseClientUserRequestConsumptionPasswordResetProps {
  userId?: string;
  onSuccess?: (response: AxiosResponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useClientUserRequestConsumptionPasswordReset = ({ userId, onSuccess, onError }: UseClientUserRequestConsumptionPasswordResetProps) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['clientUsersRequestConsumptionPasswordReset', userId],
    mutationFn: async () => {
      return await privateAxios.put(`/users/${userId}/request_consumption_password_reset`);
    },
    onSuccess,
    onError,
  });
};
