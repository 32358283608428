import Accordion from "@/common/components/Accordion";
import Checkbox from "@/common/components/Checkbox";
import MaskedTextField from "@/common/components/MaskedTextField";
import TextField from "@/common/components/TextField";
import { cnpjMask, stateInscriptionMask } from "@/common/constants/masks.constant";
import { AddressData } from "@/common/types/address";
import AddressForm from "@/common/components/AddressForm";
import { Control, FieldErrors, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { AddressValidationSchema } from "@/common/schemas/address.schema";
import { CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";
import { CompanyBranchData, CompanyData } from "@/features/company/types/company.type";
import { ImageUploader } from "@/common/components/ImageUploader";
import { checkIsImage } from "@/common/utils/fileValidator";
import { GeneralInformationData } from "@/common/types/generalInformation.type";

interface GeneralInformationProps {
  companyData?: CompanyData;
  branchData?: CompanyBranchData;
  register: UseFormRegister<CompanyBranchValidationSchema>;
  errors: FieldErrors<CompanyBranchValidationSchema>;
  control: Control<CompanyBranchValidationSchema, any>;
  watch: UseFormWatch<CompanyBranchValidationSchema>;
  setValue: UseFormSetValue<CompanyBranchValidationSchema>;
  setError: UseFormSetError<CompanyBranchValidationSchema>;
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  companyLogo?: GeneralInformationData['company_logo'];
  disableCnpj?: boolean;
}

export const GeneralInformation = ({
  companyData,
  branchData,
  register,
  errors,
  watch,
  setValue,
  setError,
  file,
  setFile,
  companyLogo,
  disableCnpj,
}: GeneralInformationProps) => {
  const generalInformationAttributes = watch('general_information_attributes');

  const willUseOwnData = generalInformationAttributes?.willUseOwnData;

  return (
    <Accordion
      contentClassName="data-[state=open]:overflow-visible"
      head={(
        <div className="flex flex-col gap-2 ">
          {errors.general_information_attributes?.willUseOwnData && (
            <p className="text-sm text-danger-soft">
              {errors.general_information_attributes.willUseOwnData.message}
            </p>
          )}
          <Checkbox
            label='Dados empresariais específicos'
            onClick={(event) => event.stopPropagation()}
            {...register('general_information_attributes.willUseOwnData')}
          />
        </div>
      )}
      body={(
        <div className="flex flex-col gap-4">
          <div className="flex gap-2 items-center">
            <div>
              <ImageUploader
                isLoading={false}
                label="Logo"
                name="profile-picture"
                accept="image/jpg, image/jpeg, image/png"
                onChange={(e) => {
                  const file = (e.target as HTMLInputElement & { files: FileList; }).files?.[0];
                  if (!file || !checkIsImage(file)) {
                    return;
                  }
                  setFile(file);
                }}
                currentImageUrl={file
                  ? URL.createObjectURL(file)
                  : companyLogo?.url
                    ? companyLogo?.url
                    : undefined
                }
                disabled={!willUseOwnData}
              />
            </div>
            <div className="flex gap-2 w-full">
              <MaskedTextField
                mask={cnpjMask}
                className="w-1/2"
                label="CNPJ"
                markAsRequired
                disabled={!willUseOwnData || disableCnpj}
                placeholder={!willUseOwnData ? companyData?.matrix_branch?.general_information?.cnpj : ''}
                value={!willUseOwnData ? '' : generalInformationAttributes?.cnpj || ''}
                errorMessage={errors?.general_information_attributes?.cnpj?.message || undefined}
                {...register('general_information_attributes.cnpj')}
              />
              <TextField
                label="Tipo de empresa"
                className="w-1/2"
                disabled
                placeholder="Privada"
              />
            </div>
          </div>
          <div className="flex gap-2">
            <TextField
              label="Nome Fantasia"
              markAsRequired
              className="w-1/2"
              maxLength={64}
              disabled={!willUseOwnData}
              placeholder={!willUseOwnData ? companyData?.matrix_branch?.general_information?.fantasy_name : ''}
              value={!willUseOwnData ? '' : generalInformationAttributes?.fantasy_name || ''}
              errorMessage={errors?.general_information_attributes?.fantasy_name?.message || undefined}
              {...register('general_information_attributes.fantasy_name')}
            />
            <TextField
              label="Nome Empresarial"
              markAsRequired
              className="w-1/2"
              maxLength={64}
              disabled={!willUseOwnData}
              placeholder={!willUseOwnData ? companyData?.matrix_branch?.general_information?.official_name : ''}
              value={!willUseOwnData ? '' : generalInformationAttributes?.official_name || ''}
              errorMessage={errors?.general_information_attributes?.official_name?.message || undefined}
              {...register('general_information_attributes.official_name')}
            />
          </div>
          <TextField
            label="Endereço eletrônico"
            markAsRequired
            maxLength={64}
            errorMessage={errors?.general_information_attributes?.email?.message || undefined}
            disabled={!willUseOwnData}
            value={!willUseOwnData ? '' : generalInformationAttributes?.email || ''}
            placeholder={!willUseOwnData ? companyData?.matrix_branch?.general_information?.email : ''}
            {...register('general_information_attributes.email')}
          />
          <div className="flex gap-2">
            <MaskedTextField
              label="IE (Inscrição Estadual)"
              markAsRequired
              mask={stateInscriptionMask}
              errorMessage={errors?.general_information_attributes?.state_inscription?.message || undefined}
              className="w-1/2"
              disabled={!willUseOwnData}
              value={!willUseOwnData ? '' : generalInformationAttributes?.state_inscription || ''}
              placeholder={!willUseOwnData ? companyData?.matrix_branch?.general_information?.state_inscription : ''}
              {...register('general_information_attributes.state_inscription')}
            />
            <TextField
              label="CNAE"
              markAsRequired
              maxLength={7}
              errorMessage={errors?.general_information_attributes?.primary_cnae?.message || undefined}
              className="w-1/2"
              disabled={!willUseOwnData}
              value={!willUseOwnData ? '' : generalInformationAttributes?.primary_cnae || ''}
              placeholder={!willUseOwnData ? companyData?.matrix_branch?.general_information?.primary_cnae : ''}
              {...register('general_information_attributes.primary_cnae')}
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <p className="text-[18px] font-bold">
                Endereço
              </p>
            </div>

            <AddressForm
              initialAddress={!willUseOwnData ? generalInformationAttributes?.address_attributes as AddressData : (branchData?.general_information?.address || {})}
              onSetAddress={(address: AddressData, valid: boolean) => {
                setValue("general_information_attributes.address_attributes", address);

                if (!valid) {
                  setError("general_information_attributes.address_attributes.zipcode", { message: "CEP não encontrado" });
                }
              }}
              disableFields={!willUseOwnData}
              errors={errors?.general_information_attributes?.address_attributes as FieldErrors<AddressValidationSchema>}
              markFieldsAsRequired
            />
          </div>
        </div>
      )}
    />
  );
};
