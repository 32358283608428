import { FormTransformers } from '@/common/utils/formTransformers'
import { FieldValues, UseFormSetError } from 'react-hook-form'

interface HandleServerErrorProps<T extends FieldValues> {
  error: any
  setBaseError: (errorMessage: string) => void
  setError?: UseFormSetError<T>
}

export const handleServerError = <T extends FieldValues>({
  error,
  setBaseError,
  setError,
}: HandleServerErrorProps<T>): void => {
  if (error?.response?.data?.errors?.base?.length) {
    setBaseError(error.response.data.errors.base)
  }

  setError &&
    FormTransformers.errorsTransformer<T>({
      setError,
    })(error)
}
