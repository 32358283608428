import usePrivateAxios from '@/common/api/usePrivateAxios'
import { ErrorResponse } from '@/common/types/errorResponse'
import { useMutation } from '@tanstack/react-query'

interface UseUpdateGovernmentAllocationEntryFile {
  governmentAllocationId?: string
  onSuccess: () => void
  onError: (error: ErrorResponse) => void
}

export const useUpdateGovernmentAllocationEntryFile = ({
  governmentAllocationId,
  onSuccess,
  onError,
}: UseUpdateGovernmentAllocationEntryFile) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['updateGovernmentAllocationEntry'],
    mutationFn: async (file: File) => {
      const government_allocation_entry = {
        file,
      }

      await privateAxios.putForm(`/billing/government_allocation_entries/${governmentAllocationId}`, {
        government_allocation_entry,
      })
    },
    onSuccess,
    onError,
  })
}
