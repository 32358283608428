import { ClientConsumptionLineItemData } from "../types/client/consumptionLineItem.type";
import { formatCurrency, formatDecimal, formatUnit } from "../utils/formatters";
import { numberToFixedHalfDown } from "../utils/numberToFixedHalfDown";

export class EventLineItemsTransformer {

  static tableTransformer() {
    return (lineItems: ClientConsumptionLineItemData[]) => {
      return lineItems.map((item) => {
        return {
          product_display_name: item.product.display_name,
          quantity: formatUnit(formatDecimal(item.quantity), item.product.unit_for_quantity),
          amount: formatCurrency(Number(item.amount || 0)),
          unitary_amount: item.quantity && item.amount ? formatCurrency(+numberToFixedHalfDown((+item.amount / +item.quantity) || 0)) : ''
        }
      });
    }
  }

}
