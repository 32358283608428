import { EmptyDataMessage } from '@/common/components/EmptyDataMessage'
import { Pagination } from '@/common/components/Table/pagination'
import { EquipmentsGridItem } from '@/features/equipments/components/EquipmentGridItem'
import { EquipmentsGridLoading } from '@/features/equipments/components/EquipmentGridLoading'
import { EquipmentData } from '@/features/equipments/types/equipments.type'
import { twMerge } from 'tailwind-merge'

interface EquipmentsGridTableProps {
  className?: string
  companyEquipments: EquipmentData[]
  page: number
  totalPages: number
  totalCount?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  isLoadingData: boolean
}

export const EquipmentsGridTable = ({
  className,
  companyEquipments,
  page,
  totalPages,
  totalCount,
  setPage,
  isLoadingData,
}: EquipmentsGridTableProps) => {
  return isLoadingData || !!companyEquipments.length ? (
    <div>
      <div
        className={twMerge(
          'grid grid-cols-[repeat(auto-fill,minmax(216px,1fr))] gap-4 overflow-y-auto max-h-[calc(100vh-358px)] pb-4',
          className,
        )}
      >
        {isLoadingData ? (
          Array(20)
            .fill(0)
            .map((_, index) => <EquipmentsGridLoading key={index} />)
        ) : (
          <>
            {companyEquipments.map((equipment) => (
              <EquipmentsGridItem key={equipment.id} equipment={equipment} />
            ))}
          </>
        )}
      </div>
      <div
        className={twMerge(
          'w-full px-4 py-2 bg-black-300 rounded-lg flex justify-between items-center sticky bottom-0',
          typeof totalCount === 'undefined' && 'justify-end',
        )}
      >
        {typeof totalCount !== 'undefined' ? (
          <p className="text-xs italic font-light">
            Mostrando {companyEquipments.length} resultados de {totalCount}
          </p>
        ) : null}
        {setPage && <Pagination currentPage={Number(page)} setPage={setPage} totalPages={totalPages} />}
      </div>
    </div>
  ) : (
    <EmptyDataMessage className={className} />
  )
}
