import usePrivateAxios from '@/common/api/usePrivateAxios'
import {
  GovernmentAllocationEntryData,
  GovernmentAllocationEntryResponse,
} from '@/features/governmentAllocations/types/governmentAllocationEntry.type'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

export const useGovernmentAllocationEntry: (id: string) => UseQueryResult<GovernmentAllocationEntryData, unknown> = (
  id,
) => {
  const privateAxios = usePrivateAxios()

  return useQuery<GovernmentAllocationEntryResponse, unknown, GovernmentAllocationEntryData>({
    queryKey: ['governmentAllocation', id],
    queryFn: () => privateAxios.get(`/billing/government_allocation_entries/${id}`),
    select: (data) => {
      return data?.data?.data
    },
  })
}
