export const governmentAllocationStatusStyle = {
  card: {
    disabled: 'bg-[#FCCDCA33] border-l-red-500',
    enabled: 'bg-blue-700',
    pending: 'bg-[#2C2C2C] border-l-black-900',
  },
  tooltip: {
    disabled: 'bg-[#766F70] border-l-red-500',
    enabled: 'bg-blue-700',
    pending: 'bg-[#2C2C2C] border-l-black-900',
  }
}
