
export const formatDateTimePriceUpdatedAt = (date: Date) => {
  const hours = date.getHours();
  const formattedMinutes = String(date.getMinutes()).padStart(2, '0');

  const formattedDate = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }).format(date);


  return `${hours}:${formattedMinutes} - ${formattedDate}`;
};
