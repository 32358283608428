import { ReactNode } from 'react';
import { ColumnPattern } from '.';
import { twMerge } from 'tailwind-merge';
import Skeleton from '../Skeleton';

export interface RowsProps {
  data: { [key: string]: unknown }[];
  columns: ColumnPattern[];
  isLoading: boolean;
  loadingRows?: number;
  className?: string;
  emptyValue?: string;
}

const Rows = ({
  data,
  columns,
  isLoading,
  loadingRows,
  className,
  emptyValue = 'Não informado',
}: RowsProps) => {
  if(data.length < 1 && !isLoading){
    return (
      <tr>
        <td colSpan={columns.length}>
          Nenhum dado nessa categoria
        </td>
      </tr>
    )
  }
  return (
    <>
      {isLoading
        ? Array(loadingRows || 10).fill(0).map((_, i) => (
          <tr key={`skeleton-${i}`} className={twMerge(className)}>
            {columns.filter((column) => !column.hidden).map((column, index) => (
              <td
                key={`skeleton-column-${index}`}
                className={twMerge('py-4 px-5 border-x border-x-white-200 font-normal text-neutral-4 text-left', column.className)}
              >
                <Skeleton height={24}/>
              </td>
            ))}
          </tr>
        ))
        : data.map((row, i) => (
            <tr key={i} className={twMerge('', className)}>
              {columns.filter((column) => !column.hidden).map((column) => (
                <td
                  className={twMerge('py-4 px-5 border-x border-x-white-200 font-normal text-neutral-4 text-left', column.className, (row['_className'] as string | undefined))}
                  style={{ overflowWrap: 'break-word' }}
                  data-testid={`table_${column.value}`}
                  key={`${column.value}-${i}`}
                >
                  {row[column.value] || row[column.value] === 0 ? (row[column.value] as ReactNode) : emptyValue}
                </td>
              ))}
            </tr>
          )
        )}
    </>
  );
};

export default Rows;
