import Checkbox from '@/common/components/Checkbox';
import React, { Dispatch } from 'react';

interface FilterCheckboxProps {
  isChecked: boolean;
  onChange: Dispatch<boolean>;
  label:string;
  id:string;
  name:string;
}

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ isChecked, onChange, label, id, name }) => {
  return (
    <div className="flex items-center h-[32px] gap-x-2 rounded-xl px-4 bg-white-100 shadow-[0px_0px_8px_4px_rgba(0,_0,_0,_0.2)]">
      <Checkbox
        id={id}
        checked={isChecked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
        name={name}
        labelClassName="text-[12px] text-white-800 italic"
        label={label}
      />
    </div>
  );
};

export default FilterCheckbox;
