import Button from '@/common/components/Button'
import { CurrencyTextField } from '@/common/components/CurrencyTextField'
import { FileUploader } from '@/common/components/FileUploader'
import MaskedTextField from '@/common/components/MaskedTextField'
import Modal from '@/common/components/Modal'
import SelectController from '@/common/components/SelectController'
import TextField from '@/common/components/TextField'
import { useCompanyBranches } from '@/common/hooks/queries/useCompanyBranches'
import { Mapper } from '@/common/services/mapper'
import { PaymentModalityType } from '@/common/types/companies/companies.type'
import { handleServerError } from '@/common/utils/handleServerError'
import { CompanyBranchData } from '@/features/company/types/company.type'
import { useCreateGovernmentAllocation } from '@/features/governmentAllocations/hooks/useCreateGovernmentAllocation'
import {
  createGovernmentAllocationSchema,
  CreateGovernmentAllocationValidationSchema,
} from '@/features/governmentAllocations/schemas/createGovernmentAllocation.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { CalendarDaysIcon } from 'lucide-react'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

export const CreateGovernmentAllocationModal = ({ paymentModality }: { paymentModality?: PaymentModalityType }) => {
  const queryClient = useQueryClient()

  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState<Blob>()
  const [fileName, setFileName] = useState('')
  const [baseError, setBaseError] = useState('')

  const { data: companyBranchOptions } = useCompanyBranches({
    mapper: Mapper.mapToOptions<CompanyBranchData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  })

  const {
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    reset: resetForm,
    formState: { errors },
  } = useForm<CreateGovernmentAllocationValidationSchema>({
    resolver: zodResolver(createGovernmentAllocationSchema),
  })

  const onSubmit: SubmitHandler<CreateGovernmentAllocationValidationSchema> = (form) => {
    setBaseError('')
    createGovernmentAllocation({ form, file })
  }

  const onSuccess = () => {
    resetForm()
    setValue('total_value', '')
    setFile(undefined)
    setFileName('')
    queryClient.invalidateQueries({ queryKey: ['governmentAllocations'] })
    setIsOpen(false)
  }

  const { mutate: createGovernmentAllocation, isPending: createGovernmentAllocationIsPending } =
    useCreateGovernmentAllocation({
      onSuccess,
      onError: (error) => {
        handleServerError({ error, setBaseError, setError })
      },
    })

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={handleSubmit(onSubmit)}
      Trigger={
        <Button className="rounded-sm shadow-[0px_0px_8px_4px_rgba(0,_0,_0,_0.2)]">
          {paymentModality === 'pre_paid' ? 'Novo empenho' : 'Nova cota estimativa'}
        </Button>
      }
      title={`Cadastrando ${paymentModality === 'pre_paid' ? 'empenho' : 'cota estimativa'}`}
      footer={`Cadastrar ${paymentModality === 'pre_paid' ? 'empenho' : 'cota estimativa'}`}
      body={
        <form className="flex flex-col gap-4 text-white-800">
          {baseError && <p className="text-sm text-danger-soft">{baseError}</p>}

          <SelectController
            control={control}
            name="branch_id"
            label="Filial"
            markAsRequired
            options={companyBranchOptions || []}
            errorMessage={errors.branch_id?.message}
            isDisabled={createGovernmentAllocationIsPending}
          />

          <div className="space-y-2">
            <p className="text-[11px] leading-3">Período de competência</p>

            <div className="flex items-start gap-x-2">
              <MaskedTextField
                LeftIcon={<CalendarDaysIcon strokeWidth={1} />}
                label="Início"
                markAsRequired
                mask="00/00/0000"
                className="w-1/2"
                errorMessage={errors.start_of_document_validity?.message}
                {...register('start_of_document_validity')}
                disabled={createGovernmentAllocationIsPending}
              />

              <MaskedTextField
                LeftIcon={<CalendarDaysIcon strokeWidth={1} />}
                label="Fim"
                markAsRequired
                mask="00/00/0000"
                className="w-1/2"
                errorMessage={errors.end_of_document_validity?.message}
                {...register('end_of_document_validity')}
                disabled={createGovernmentAllocationIsPending}
              />
            </div>
          </div>

          <TextField
            label="Número do ofício do empenho"
            markAsRequired
            maxLength={24}
            errorMessage={errors?.office_number?.message}
            {...register('office_number')}
            disabled={createGovernmentAllocationIsPending}
          />

          <CurrencyTextField
            label={`Valor total ${paymentModality === 'pre_paid' ? 'do empenho' : 'da cota estimativa'}`}
            markAsRequired
            maxValue={1_000_000_000}
            errorMessage={errors.total_value?.message}
            {...register('total_value')}
            disabled={createGovernmentAllocationIsPending}
          />

          {paymentModality === 'pre_paid' && (
            <FileUploader
              name="file"
              accept="application/pdf, image/png, image/jpg, image/jpeg"
              title="Anexe o Empenho"
              className="w-full bg-yellow-op-2 border-none"
              fileName={fileName ? fileName : undefined}
              currentFileUrl={file ? URL.createObjectURL(file) : undefined}
              handleChange={(file) => {
                setFile(file)
                setFileName(file.name)
              }}
              disabled={createGovernmentAllocationIsPending}
            />
          )}
        </form>
      }
    />
  )
}
