import ListFilters from "@/common/components/ListFilters";
import ListHeader from "@/common/components/ListHeader";
import Table from "@/common/components/Table";
import TextField from "@/common/components/TextField";
import { DatePickerWithRange } from "@/common/components/ui/dateRangePicker";
import { useCompanyBranches } from "@/common/hooks/queries/useCompanyBranches";
import useDebounce from "@/common/hooks/useDebounce";
import { useFilterDate } from "@/common/hooks/useFilterDate";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { Mapper } from "@/common/services/mapper";
import { CompanyBranchData } from "@/common/types/companyBranches.type";
import { Option } from "@/common/types/option";
import { billingEventsFiscalDocumentsStatusOptions } from "@/features/billing/constants/eventsFiscalDocumentsStatusOptions.constant";
import { fiscalDocumentGroupsDefaultColumns } from "@/features/billing/constants/fiscalDocumentGroupsDefaultColumns.constant";
import { useBillingFiscalDocumentGroups } from "@/features/billing/hooks/useBillingFiscalDocumentGroups";
import { BillingFiscalDocumentGroupsTransformer } from "@/features/billing/services/fiscalDocumentGroupsTransformer";
import { useEstablishmentCompanies } from "@/features/companyBranches/hooks/useEstablishmentCompanies";
import { EstablishmentCompanyTransformer } from "@/features/companyBranches/services/establishmentCompanyTransformer";
import { Search, TablePropertiesIcon } from "lucide-react";

const BillingFiscalDocumentGroupsList = () => {
  const { filterParams, setFilterParams } = useFilterParams();
  const { date, formattedDate } = useFilterDate(filterParams);

  const [search, setSearch] = useDebounce(filterParams.number || '');
  const [searchClientBranch, setSearchClientBranch] = useDebounce('');
  const [searchEstablishmentCompany, setSearchEstablishmentCompany] = useDebounce('');

  const { data: clientBranchesOptions } = useCompanyBranches({
    filters: { search: searchClientBranch },
    mapper: Mapper.mapToOptions<CompanyBranchData>({ valueFieldName: 'id', labelFieldName: 'display_name' }),
  });

  const { data: establishmentCompanyOptions } = useEstablishmentCompanies({
    filters: { search: searchEstablishmentCompany },
    mapper: Mapper.mapWithTransformer({ transformer: EstablishmentCompanyTransformer.optionTransformer() }),
  });

  const { data: fiscalDocumentGroups, meta, isFetching: fiscalDocumentGroupsIsFetching } = useBillingFiscalDocumentGroups({
    filters: {
      page: filterParams.page || 1,
      search,
      createdAtStartDate: formattedDate.from,
      createdAtEndDate: formattedDate.to,
      clientBranchId: filterParams.clientBranch?.value,
      establishmentBranchId: filterParams.establishmentCompany?.value,
      fiscalDocumentStatus: filterParams.status?.value,
      fiscalDocumentRequirement: 'per_bill',
    },
    mapper: Mapper.mapWithTransformer({ transformer: BillingFiscalDocumentGroupsTransformer.tableTransformer() })
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <ListHeader
          Icon={<TablePropertiesIcon className="w-10 h-10" strokeWidth={1} />}
          title="Recolha de notas por lote"
        />
      </div>

      <div className="h-[calc((100vh-88px-2rem-1.5rem))] flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <TextField
            LeftIcon={<Search />}
            placeholder="Pesquisar"
            className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
            onChange={(e) => {
              setSearch((e.target as HTMLInputElement).value);
              setFilterParams((prev) => ({ ...prev, search: (e.target as HTMLInputElement).value }));
            }}
            defaultValue={filterParams.search}
          />

          <div className="flex items-center justify-start gap-2">
            <DatePickerWithRange date={date} setDate={(date) => setFilterParams((prev) => ({ ...prev, date }))} />

            <ListFilters
              filters={[
                {
                  className: '',
                  onChange: (status) => setFilterParams((prev) => ({ ...prev, status })),
                  defaultValue: filterParams.status,
                  options: [{ label: 'Todas', value: '' }, ...billingEventsFiscalDocumentsStatusOptions],
                  placeholder: 'Status'
                },
                {
                  className: '',
                  onChange: (establishmentCompany) => setFilterParams((prev) => ({ ...prev, establishmentCompany })),
                  onInputChange: setSearchEstablishmentCompany,
                  defaultValue: filterParams.establishmentCompany,
                  options: [{ label: 'Todas', value: '' }, ...establishmentCompanyOptions as Option[]],
                  placeholder: 'Estabelecimento'
                },
                {
                  className: '',
                  onChange: (clientBranch) => setFilterParams((prev) => ({ ...prev, clientBranch })),
                  onInputChange: setSearchClientBranch,
                  value: filterParams.clientBranch || '',
                  options: [{ label: 'Todas', value: '' }, ...clientBranchesOptions as Option[]],
                  placeholder: 'Filial'
                },
              ]}
            />
          </div>
        </div>

        <Table
          columns={fiscalDocumentGroupsDefaultColumns}
          isLoadingData={fiscalDocumentGroupsIsFetching}
          page={Number(filterParams.page) || 1}
          rows={fiscalDocumentGroups}
          totalPages={meta.total_pages}
          totalCount={meta.total}
          hasPagination
          setPage={(page) => setFilterParams((prev) => ({ ...prev, page }))}
          rowEmptyValue="--"
        />
      </div>
    </div>
  );
};

export default BillingFiscalDocumentGroupsList;
