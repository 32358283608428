import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Cluster } from '../types/cluster';
import { EstablishmentClustersFilterProps } from '../types/clusterFilters';
import QueryString from "qs"
import usePrivateAxios from '@/common/api/usePrivateAxios';

export const useEstablishmentClusters = (filters: EstablishmentClustersFilterProps) => {
  const privateAxios = usePrivateAxios();
  const hasQueryParams = Object.keys(filters).length > 0;

  return useQuery({
    queryKey: ['establishmentClusters', filters],
    queryFn: async () => {
      const queryString = QueryString.stringify(filters);
      const { data } = await privateAxios.get<{ data: Cluster[] }>(
        `/establishment/companies/geo_clustered?${queryString}`,
      );
      return data;
    },
    placeholderData: keepPreviousData,
    select: (returnedData) => returnedData?.data || [],
    enabled: hasQueryParams,
  });
};
