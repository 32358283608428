import Box from "@/common/components/Box";
import Button from "@/common/components/Button";
import { FileUploader } from "@/common/components/FileUploader";
import Table from "@/common/components/Table";
import { lineItemsDefaultColumns } from "@/common/constants/lineItemsDefaultColumns.constant";
import { useGoBack } from "@/common/hooks/useGoBack";
import { EventLineItemsTransformer } from "@/common/services/eventLineItemsTransformer";
import { ClientConsumptionLineItemData } from "@/common/types/client/consumptionLineItem.type";
import { formatCurrency } from "@/common/utils/formatters";
import { getFilenameFromUrl } from "@/common/utils/getFilenameFromUrl";
import EventCardDetails from "@/features/billing/components/EventsWithFiscalDocumentRequirement/EventCardDetails";
import EventResume from "@/features/billing/components/EventsWithFiscalDocumentRequirement/EventResume";
import FiscalDocumentRejected from "@/features/billing/components/EventsWithFiscalDocumentRequirement/FiscalDocumentRejected";
import { fiscalDocumentFileClassName } from "@/features/billing/constants/fiscalDocumentFileClassName.constant";
import useBillingEvent from "@/features/billing/hooks/useBillingEvent";
import { ChevronLeftIcon, InfoIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const BillingEventsWithFiscalDocumentRequirementShow = () => {
  const { id } = useParams();
  const goBack = useGoBack();

  const { data: event, isFetching: eventIsFetching } = useBillingEvent({ id });

  const fiscalDocumentStatus = event?.eventable?.fiscal_document_status;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <Button variant="link-white" onClick={() => goBack({ fallback: '/events-with-fiscal-document-requirement' })}>
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>

          <h2 className="text-2xl font-bold">Transação {event?.protocol_number}</h2>
        </div>
      </div>

      <Box className="h-[calc((100vh-88px-2rem-1.5rem))] overflow-auto flex-col flex gap-6">
        <EventResume event={event} />

        {event?.eventable?.card && <EventCardDetails card={event.eventable.card} />}

        <div>
          <Table
            className="max-h-[210px]"
            columns={lineItemsDefaultColumns}
            rows={EventLineItemsTransformer.tableTransformer()((event?.eventable?.line_items || []) as unknown as ClientConsumptionLineItemData[]) || []}
            isLoadingData={eventIsFetching}
            hasPagination={false}
            page={1}
            totalPages={1}
            bottomContent={(
              <div className="flex justify-between w-full">
                <p className="text-xs">Total</p>
                <p className="text-blue-800 text-sm">{formatCurrency(Number(event?.total_amount) || 0)}</p>
              </div>
            )}
          />
        </div>

        <div className="flex flex-col gap-3">
          <h3 className="text-xl font-bold">Nota fiscal</h3>
          {event?.eventable?.fiscal_document ? (
            <>
              {fiscalDocumentStatus === 'rejected' && <FiscalDocumentRejected fiscalDocument={event?.eventable?.fiscal_document} />}

              <FileUploader
                disabled
                id="uploaded_fiscal_document"
                className={twMerge("w-56", fiscalDocumentStatus && fiscalDocumentFileClassName[fiscalDocumentStatus as keyof typeof fiscalDocumentFileClassName])}
                fileName={event?.eventable?.fiscal_document?.xml?.url ? getFilenameFromUrl(event?.eventable?.fiscal_document?.xml?.url) : ''}
                currentFileUrl={event?.eventable?.fiscal_document?.xml?.url}
              />
            </>
          ) : (
            <div className="flex gap-2">
              <InfoIcon className="w-[28px]" strokeWidth={1} color="var(--warning)" />

              <div className="flex flex-col">
                <p className="text-xs font-bold">O estabelecimento ainda não enviou essa nota.</p>
                <p className="text-xs italic font-light">Caso o prazo termine, a fatura será cancelada e sua empresa não será debitada.</p>
              </div>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default BillingEventsWithFiscalDocumentRequirementShow;
