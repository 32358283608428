import Button from "@/common/components/Button";
import Modal from "@/common/components/Modal";
import { useState } from "react";
import { useRequestPasswordReset } from "../hooks/useRequestPasswordReset";
import { ModalMessageSimple } from "@/common/components/ModalMessageSimple";

const RequestPasswordReset = ({ id }: { id: string; }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [messages, setMessages] = useState<string[] | boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { mutate: requestPasswordReset, isPending: requestPasswordResetIsPending } = useRequestPasswordReset({
    userId: id,
    onSuccess: () => {
      setIsOpen(false);
      setIsError(false);
      setMessages(['Senha enviada ao usuário por e-mail e/ou SMS.']);
    },
    onError: (error) => {
      setIsOpen(false);
      setIsError(true);
      setMessages((error.response?.data as { errors: { base: string[]; }; })?.errors?.base || null);
    },
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        Trigger={
          <Button disabled={requestPasswordResetIsPending}>
            Redefinir senha
          </Button>
        }
        useCancellableFooter
        showClose={false}
        title="Você deseja realmente alterar essa senha?"
        subtitle="Ao clicar em confirmar, a senha anterior do usuário será desativada."
        subtitleClassName="mt-1 text-md text-white-300"
        confirmButtonClassName="bg-blue-700"
        onConfirm={() => requestPasswordReset()}
      />

      {messages && (
        <ModalMessageSimple
          messages={messages as string[]}
          setMessages={setMessages as React.Dispatch<React.SetStateAction<boolean>>}
          isError={isError}
        />
      )}
    </>
  );
};

export default RequestPasswordReset;
