import { formatCurrency } from "@/common/utils/formatters";
import { transformToNumber } from "@/common/utils/transformers";
import { EstablishmentCompanyData } from "@/features/company/types/company.type";
import { ArrowBigRight } from "lucide-react";
import { Link } from "react-router-dom";

export class EstablishmentCompanyTransformer {
  static tableTransformer() {
    return (establishmentCompany: EstablishmentCompanyData[]) => {
      return establishmentCompany.map((establishmentCompany) => {
        const unpaidFeesTotal = transformToNumber({ value: establishmentCompany?.unpaid_fees_total, invert: true });
        return {
          ...establishmentCompany,
          primary_cnae: establishmentCompany.general_information?.primary_cnae,
          display_name: establishmentCompany.general_information?.fantasy_name || establishmentCompany.general_information?.official_name,
          lifetime_revenue_amount: establishmentCompany.lifetime_revenue_amount && formatCurrency(Number(establishmentCompany.lifetime_revenue_amount)),
          receivable_amount: establishmentCompany.receivable_amount && formatCurrency(Number(establishmentCompany.receivable_amount)),
          unpaid_fees_total: (
            <p className={unpaidFeesTotal > 0 ? 'text-valid' : unpaidFeesTotal < 0 ? 'text-danger-soft' : ''}>
              {formatCurrency(unpaidFeesTotal)}
            </p>
          ),
          fee_total: establishmentCompany.fee_total && formatCurrency(Number(establishmentCompany.fee_total)),
          actions: <Link to={`/establishments/${establishmentCompany.id}`}><ArrowBigRight /></Link>
        };
      });
    };
  }

  static optionTransformer() {
    return (establishmentCompany: EstablishmentCompanyData[]) => {
      return establishmentCompany.map((establishmentCompany) => (
        {
          label: `${establishmentCompany.general_information?.fantasy_name} (${establishmentCompany.general_information?.official_name})`,
          value: establishmentCompany.matrix_branch_id
        }
      ));
    };
  }
}
