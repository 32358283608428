import { BadgeAlertIcon } from 'lucide-react'

interface RequestedReviewProps {
  description?: string
}

export const RequestedReview = ({ description }: RequestedReviewProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-1 text-warning">
        <BadgeAlertIcon strokeWidth={1} />
        <span className="font-bold">Revisão solicitada</span>
      </div>
      {description && (
        <div className="bg-yellow-op-2 p-4 w-full h-36 text-white-800 text-sm overflow-y-auto">
          <p>{description}</p>
        </div>
      )}
    </div>
  )
}
