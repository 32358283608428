import { PaymentModalityType } from '@/common/types/companies/companies.type'
import { formatCurrency } from '@/common/utils/formatters'
import { GovernmentAllocationData } from '@/features/governmentAllocations/types/governmentAllocation.type'

type GovernmentAllocationDetailsProps = {
  governmentAllocation: GovernmentAllocationData
  paymentModality?: PaymentModalityType
}

export const GovernmentAllocationDetails = ({
  governmentAllocation,
  paymentModality,
}: GovernmentAllocationDetailsProps) => {
  return (
    <div className="flex gap-4 items-end">
      <div className="flex flex-col gap-2">
        <p className="text-[10px] leading-3">{governmentAllocation.main_entry.office_number}</p>

        <div>
          <p className="text-[10px] font-light italic leading-3">
            {paymentModality === 'pre_paid' ? 'Empenhado' : 'Cotado'}
          </p>
          <p className="text-xs font-bold leading-3">{formatCurrency(Number(governmentAllocation.total_value))}</p>
        </div>

        <div className="text-yellow-400">
          <p className="text-[10px] font-light italic leading-3">Consumido</p>
          <p className="text-xs font-bold leading-3">{formatCurrency(Number(governmentAllocation.consumed_total))}</p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div>
          <p className="text-[10px] font-light italic leading-3">Aprovado</p>
          <p className="text-xs font-bold leading-3">{formatCurrency(Number(governmentAllocation.approved_value))}</p>
        </div>

        <div className="text-valid">
          <p className="text-[10px] font-light italic leading-3">Disponível</p>
          <p className="text-xs font-bold leading-3">{formatCurrency(Number(governmentAllocation.remaining_total))}</p>
        </div>
      </div>
    </div>
  )
}
