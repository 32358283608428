import { ClientConsumptionData } from "@/common/types/client/consumption.type";
import { BadgeAlertIcon } from "lucide-react";

const FiscalDocumentRejected = ({ fiscalDocument }: { fiscalDocument: ClientConsumptionData['fiscal_document'] }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-1 text-warning">
        <BadgeAlertIcon strokeWidth={1} /><span className="font-bold">Nota fiscal rejeitada</span>
      </div>

      {fiscalDocument?.rejection_reason && (
        <div className="bg-yellow-op-2 p-4 w-full h-36 text-white-800 text-sm overflow-y-auto rounded-md">
          <p>{fiscalDocument?.rejection_reason}</p>
        </div>
      )}
    </div>
  );
};

export default FiscalDocumentRejected;
