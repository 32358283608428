import { privateAxios } from "@/common/api/axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { FiscalDocumentGroupData, FiscalDocumentGroupResponse } from "../types/fiscalDocumentGroup.type";

type UseBillingFiscalDocumentType = ({ }: { id?: string; }) => UseQueryResult<FiscalDocumentGroupData, unknown>;

const useBillingFiscalDocumentGroup: UseBillingFiscalDocumentType = ({ id }) => {
  return useQuery<FiscalDocumentGroupResponse, unknown, FiscalDocumentGroupData>({
    enabled: !!id,
    queryKey: ['billingFiscalDocumentGroup', id],
    queryFn: () => {
      return privateAxios.get(`/billing/fiscal_document_groups/${id}`);
    },
    select: (data) => data?.data?.data,
  });
};

export default useBillingFiscalDocumentGroup;
