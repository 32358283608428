import { SearchIcon } from "lucide-react";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { useFilterDate } from "@/common/hooks/useFilterDate";
import { logChangesTableColumns } from "@/common/constants/logChangesTableColumns";
import { Mapper } from "@/common/services/mapper";
import { LogChangesTransformer } from "@/common/services/logChangesTransformer";
import TextField from "@/common/components/TextField";
import Table from "@/common/components/Table";
import { DatePickerWithRange } from "@/common/components/ui/dateRangePicker";
import useEquipmentLogChanges from "@/features/equipments/hooks/useEquipmentLogChanges";

const EquipmentLogChanges = ({ equipmentId }: { equipmentId?: string; }) => {
  const { filterParams, setFilterParams } = useFilterParams();

  const { date, formattedDate } = useFilterDate(filterParams);

  const { data: logChanges, meta, isFetching } = useEquipmentLogChanges({
    equipmentId,
    filters: {
      search: filterParams.search,
      page: filterParams.page,
      relatedToOrItemId: equipmentId,
      createdAtGt: formattedDate.from,
      createdAtLt: formattedDate.to,
    },
    mapper: Mapper.mapWithTransformer({
      transformer: LogChangesTransformer.tableTransformer(),
    })
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <TextField
          LeftIcon={<SearchIcon />}
          placeholder="Pesquisar por ID"
          className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
          defaultValue={filterParams.search}
          onChange={(e: any) => setFilterParams((params) => ({ ...params, search: e.target.value }))}
        />

        <div className="flex">
          <DatePickerWithRange date={date} setDate={(date) => setFilterParams((params) => ({ ...params, date }))} />
        </div>
      </div>

      <div>
        <Table
          className="max-h-[calc(100vh-268px-3rem)]"
          columns={logChangesTableColumns}
          rows={logChanges}
          page={filterParams.page}
          totalCount={meta.total}
          totalPages={meta.total_pages}
          isLoadingData={isFetching}
        />
      </div>
    </div>
  );
};

export default EquipmentLogChanges;
