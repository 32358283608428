import { z } from 'zod'

export const updateSchema = z.object({
  office_number: z.string().optional().nullable(),
  government_allocation_attributes: z
    .object({
      start_of_document_validity: z
        .string()
        .optional()
        .nullable()
        .refine(
          (value) => {
            if (!!value && value.length < 10) return false
            return true
          },
          { message: 'Use uma data válida' },
        ),
      end_of_document_validity: z
        .string()
        .optional()
        .nullable()
        .refine(
          (value) => {
            if (!!value && value.length < 10) return false
            return true
          },
          { message: 'Use uma data válida' },
        ),
    })
    .optional()
    .nullable(),
  total_value: z.string().optional().nullable(),
  product_categories: z
    .array(
      z
        .object({
          id: z.string(),
          total_value: z.string().optional().nullable(),
          product_category_products: z
            .array(
              z
                .object({
                  id: z.string(),
                  total_value: z.string().optional().nullable(),
                })
                .optional()
                .nullable(),
            )
            .optional()
            .nullable(),
        })
        .optional()
        .nullable(),
    )
    .optional()
    .nullable(),
})

export type GovernmentAllocationEntryUpdateValidationSchema = z.infer<typeof updateSchema>
