import { BadgeHelp } from "lucide-react";
import { Link } from "react-router-dom";
import Button from "../Button";

export type ListHeaderProps = {
  Icon: JSX.Element;
  title: string;
  createButtonText?: string;
  disableCreateButton?: boolean;
};

const ListHeader = ({
  Icon,
  title,
  createButtonText,
  disableCreateButton,
}: ListHeaderProps) => {
  return (
    <div className="w-full flex justify-between items-center">
      <div className="flex gap-2 items-center">
        {Icon}
        <h2 className="text-2xl font-bold">{title}</h2>
        <BadgeHelp strokeWidth={1} color="var(--warning)" />
      </div>
      {createButtonText
        ? <Link to={disableCreateButton ? '' : 'create'} className={disableCreateButton ? 'cursor-default' : ''}>
          <Button className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]" disabled={disableCreateButton}>
            {createButtonText}
          </Button>
        </Link>
        : null
      }
    </div>
  );
};

export default ListHeader;
