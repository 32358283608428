import { privateAxios } from "@/common/api/axios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type UseBillingEventsMissingFiscalDocumentProps<T> = {
  filters?: {
    search?: string;
    page?: number;
    clientCompanyId?: string;
    clientBranchId?: string;
    establishmentBranchId?: string;
    createdAtStartDate?: string;
    createdAtEndDate?: string;
    fiscalDocumentRequirement?: 'none' | 'per_transaction' | 'per_bill';
    fiscalDocumentStatus?: string;
  };
  mapper?: (data: any) => T,
};

export const useBillingFiscalDocumentGroups = <T,>({
  filters: {
    search = '',
    page,
    createdAtStartDate,
    createdAtEndDate,
    clientCompanyId,
    clientBranchId,
    establishmentBranchId,
    fiscalDocumentRequirement,
    fiscalDocumentStatus,
  } = {},
  mapper = Mapper.default<T>(),
}: UseBillingEventsMissingFiscalDocumentProps<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    queryKey: [
      'billingFiscalDocumentGroups',
      search,
      page,
      createdAtStartDate,
      createdAtEndDate,
      clientCompanyId,
      clientBranchId,
      establishmentBranchId,
      fiscalDocumentRequirement,
      fiscalDocumentStatus,
    ],
    queryFn: () => {
      return privateAxios.get('/billing/fiscal_document_groups', {
        params: {
          search,
          page,
          'q[created_at_gteq]': createdAtStartDate,
          'q[created_at_lteq]': createdAtEndDate,
          'q[client_company_id_eq]': clientCompanyId,
          'q[client_branch_id_eq]': clientBranchId,
          'q[establishment_branch_id_eq]': establishmentBranchId,
          'q[fiscal_document_requirement_eq]': fiscalDocumentRequirement,
          'q[fiscal_document_status_eq]': fiscalDocumentStatus,
        }
      });
    },
    select: (data: { data: { data: any[], meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });
  return { data, meta, isPending, ...rest };
};
