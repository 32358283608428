import Box from '@/common/components/Box';
import Button from '@/common/components/Button';
import MaskedTextField from '@/common/components/MaskedTextField';
import SelectController from '@/common/components/SelectController';
import Spinner from '@/common/components/Spinner';
import TextField from '@/common/components/TextField';
import { nameMask, phoneMask } from '@/common/constants/masks.constant';
import { useRoles } from '@/common/hooks/queries/useRoles';
import { useGoBack } from '@/common/hooks/useGoBack';
import { Mapper } from '@/common/services/mapper';
import { RoleData } from '@/common/types/roles';
import { FormTransformers } from '@/common/utils/formTransformers';
import { useClientUser } from '@/features/users/hooks/useClientUser';
import { useUpdateClientUser } from '@/features/users/hooks/useUpdateClientUser';
import {
  clientUserSchema,
  ClientUserValidationSchema,
} from '@/features/users/schemas/create.schema';
import { clientUserDefaultValues } from '@/features/users/utils/clientUserDefaultValues';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { ChevronLeftIcon } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { statusOptions } from '../../constants/statusOptions.constant';
import { useBranches } from '@/common/hooks/queries/useBranches';
import { ImageUploader } from '@/common/components/ImageUploader';
import { checkIsImage } from '@/common/utils/fileValidator';
import { useState } from 'react';
import { ModalMessageSimple } from '@/common/components/ModalMessageSimple';

const Update = () => {
  const { id: clientUserId } = useParams();
  const [file, setFile] = useState<Blob>();
  const goBack = useGoBack();
  const queryClient = useQueryClient();
  const [messages, setMessages] = useState<string[] | boolean>(false);

  const { data: clientUserData } = useClientUser({
    id: clientUserId || '',
  });

  const { data: rolesOptions } = useRoles({
    mapper: Mapper.mapToOptions<RoleData>({
      labelFieldName: 'display_name',
      valueFieldName: 'id',
    }),
  });

  const { data: branchOptions } = useBranches({
    mapper: Mapper.mapToOptions<RoleData>({
      labelFieldName: 'display_name',
      valueFieldName: 'id',
    }),
  })

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<ClientUserValidationSchema>({
    resolver: zodResolver(clientUserSchema),
    defaultValues: clientUserDefaultValues(clientUserData),
  });

  const {
    mutate: updateClientUser,
    isPending: updateClientUserIsPending,
  } = useUpdateClientUser({
    clientUserId: clientUserId,
    clientUserData: clientUserData,
    file,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['clientUser', clientUserId],
      });
      goBack({ fallback: `/users/${clientUserId}` });
    },
    onError: (error) => {
      FormTransformers.errorsTransformer<ClientUserValidationSchema>({
        setError,
        setMessages,
      })(error);
    },
  });

  const onSubmit: SubmitHandler<ClientUserValidationSchema> = (data) => {
    updateClientUser(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-4 items-center">
          <Button
            variant="link-white"
            onClick={() =>
              goBack({ fallback: `/users/${clientUserId}` })
            }
          >
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>
          <h2 className="text-2xl font-bold">
            {clientUserData?.full_name}
          </h2>
        </div>
        <div className='flex gap-4'>
          <Button
            variant="outline-error"
            onClick={() => goBack({ fallback: '/users' })}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]"
            disabled={updateClientUserIsPending}
          >
            {updateClientUserIsPending ? (
              <Spinner className="h-6 mx-[23px] fill-white-800" />
            ) : (
              'Confirmar'
            )}
          </Button>
        </div>
      </div>
      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-128px)]">
        <p className="text-lg font-bold">Dados pessoais</p>
        <ImageUploader
          isLoading={false}
          label="Foto"
          name="profile-picture"
          accept="image/*"
          onChange={(e) => {
            const file = (e.target as HTMLInputElement & { files: FileList }).files?.[0]
            if (!file || !checkIsImage(file)) {
              return
            }
            setFile((e.target as HTMLInputElement & { files: FileList }).files[0])
          }}
          currentImageUrl={file
            ? URL.createObjectURL(file)
            : clientUserData?.profile_picture?.url
              ? clientUserData?.profile_picture?.url
              : undefined
          }
          disabled={updateClientUserIsPending}
        />
        <div className="flex gap-2 w-full justify-between">
          <div className="w-3/4">
            <MaskedTextField
              label="CPF"
              markAsRequired
              mask="000.000.000-00"
              errorMessage={errors?.cpf?.message || undefined}
              {...register('cpf')}
              disabled={updateClientUserIsPending}
            />
            <Button variant="link">Importar dados</Button>
          </div>
          <MaskedTextField
            label="Data de nascimento"
            markAsRequired
            mask="00/00/0000"
            className="w-1/4"
            errorMessage={errors.date_of_birth?.message || undefined}
            {...register('date_of_birth')}
            disabled={updateClientUserIsPending}
          />
        </div>
        <div className="flex gap-2 w-full justify-between">
          <MaskedTextField
            label="Nome"
            markAsRequired
            mask={nameMask}
            maxLength={64}
            className="w-3/4"
            errorMessage={errors.full_name?.message || undefined}
            {...register('full_name')}
            disabled={updateClientUserIsPending}
          />
          <SelectController
            control={control}
            name="user_roles"
            label="Perfil"
            markAsRequired
            className="w-1/4"
            errorMessage={errors.user_roles?.message || undefined}
            options={rolesOptions || []}
            isMulti
            isDisabled={updateClientUserIsPending}
          />
        </div>
        <SelectController
          control={control}
          name="status"
          label="Status"
          markAsRequired
          errorMessage={errors.status?.message || undefined}
          options={statusOptions}
          isDisabled={updateClientUserIsPending}
        />
        <TextField
          label="Matrícula"
          errorMessage={errors.enrollment_number?.message || undefined}
          {...register('enrollment_number')}
          disabled={updateClientUserIsPending}
        />
        <SelectController
          control={control}
          name="branch_id"
          label="Filial"
          markAsRequired
          options={branchOptions || []}
          errorMessage={errors.branch_id?.message || undefined}
          isDisabled={updateClientUserIsPending}
        />
        <MaskedTextField
          label="Telefone"
          mask={phoneMask}
          errorMessage={errors.phone?.number?.message || undefined}
          {...register('phone.number')}
          disabled={updateClientUserIsPending}
        />
        <TextField
          label="Email"
          {...register('email')}
          errorMessage={errors.email?.message || undefined}
          disabled={updateClientUserIsPending}
        />
      </Box>

      {messages && (
        <ModalMessageSimple
          messages={messages as string[]}
          setMessages={setMessages as React.Dispatch<React.SetStateAction<boolean>>}
          isError
        />
      )}
    </form>
  );
};

export default Update;
