import BoxWithHeader from '@/common/components/BoxWithHeader'
import Checkbox from '@/common/components/Checkbox'
import { RequirementListSummaryData } from '@/common/types/requirementListSummary.type'

interface ChecklistProps {
  requirement_list_summary?: RequirementListSummaryData
  state?: string
  Buttons?: () => JSX.Element
  className?: string
}

export const Checklist = ({ requirement_list_summary, state, Buttons, className }: ChecklistProps) => {
  return (
    <BoxWithHeader title="Checklist" subtitle={requirement_list_summary?.description} bodyClassName={className}>
      <div className="flex flex-col gap-2">
        {requirement_list_summary?.steps?.map((step) => (
          <Checkbox label={step.name} name={step.name} checked={step.passed} disabled labelClassName="italic text-xs" />
        ))}
      </div>
      <div className="flex flex-col gap-2">
        {Buttons && <Buttons />}
        {!!state && <p className="text-xs italic text-center">{state}</p>}
      </div>
    </BoxWithHeader>
  )
}
