import { privateAxios } from "@/common/api/axios";
import { BillingEventData, BillingEventResponse } from "@/common/types/billing/event.type";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

type UseBillingEventType = ({ }: { id?: string; }) => UseQueryResult<BillingEventData, unknown>;

const useBillingEvent: UseBillingEventType = ({ id }) => {
  return useQuery<BillingEventResponse, unknown, BillingEventData>({
    enabled: !!id,
    queryKey: ['billingEvent', id],
    queryFn: () => {
      return privateAxios.get(`/billing/events/${id}`);
    },
    select: (data) => data?.data?.data,
  });
};

export default useBillingEvent;
