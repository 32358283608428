import Box from "@/common/components/Box";
import Button from "@/common/components/Button";
import Spinner from "@/common/components/Spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronLeft, ChevronsRight } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { BranchInformation } from "../../components/form/BranchInformation";
import { useMineCompany } from "@/features/company/hooks/useMineCompany";
import { GeneralInformation } from "../../components/form/GeneralInformation";
import { MasterClientUser } from "../../components/form/MasterClientUser";
import { CreditConfiguration } from "../../components/form/CreditConfiguration";
import { ConsumptionRestriction } from "../../components/form/ConsumptionRestriction";
import { PeriodRestriction } from "../../components/form/PeriodRestriction";
import { companyBranchSchema, CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";
import { companyBranchDefaultPeriodRestrictionData } from "@/features/equipments/utils/companyBranchDefaultPeriodRestrictionData";
import useCreateCompanyBranch from "../../hooks/useCreateCompanyBranch";
import { useState } from "react";

const CompanyBranchesCreate = () => {
  const navigate = useNavigate();

  const [baseError, setBaseError] = useState<string[]>([]);
  const [file, setFile] = useState<File>();

  const { data: companyData } = useMineCompany();

  const { mutate: createCompanyBranch, isPending } = useCreateCompanyBranch({
    onSuccess: () => {
      navigate(`/company-branches`);
    },
    onError: (error) => {
      setBaseError((error?.response?.data as unknown as { errors: { base: string[]; }; })?.errors?.base);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm<CompanyBranchValidationSchema>({
    resolver: zodResolver(companyBranchSchema),
    defaultValues: {
      period_restrictions_attributes: companyBranchDefaultPeriodRestrictionData(),
    }
  });

  const onSubmit: SubmitHandler<CompanyBranchValidationSchema> = (data) => {
    createCompanyBranch({ form: data, file });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-2 items-center">
          <Link to='/company-branches' className="cursor-pointer">
            <ChevronLeft size={40} strokeWidth={1} />
          </Link>

          <p className="text-white-400 font-normal">
            Estrutura
          </p>

          <ChevronsRight color='#ffffffb3' />

          <h2>
            Cadastrar filial
          </h2>
        </div>

        <div className="flex gap-4">
          <Link to='/company-branches'>
            <Button variant='outline-error'>
              Cancelar
            </Button>
          </Link>

          <Button type="submit" className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]" >
            {isPending ? <Spinner className="h-6 mx-[23px] fill-white-800" /> : 'Cadastrar Filial'}
          </Button>
        </div>
      </div>

      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto h-[calc(100vh-128px)]">
        {baseError?.map((error) => (
          <p className="text-sm text-danger-soft">
            {error}
          </p>
        ))}

        <p className="text-lg font-bold">
          Dados da filial
        </p>

        <BranchInformation
          register={register}
          errors={errors}
          control={control}
          showParentIdField
        />

        <GeneralInformation
          companyData={companyData}
          register={register}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          setError={setError}
          file={file}
          setFile={setFile}
          disableCnpj={false}
        />

        <MasterClientUser
          errors={errors}
          control={control}
        />

        <CreditConfiguration
          companyData={companyData}
          register={register}
          control={control}
          watch={watch}
          errors={errors}
        />

        <ConsumptionRestriction
          register={register}
          errors={errors}
          disabled={isPending}
        />

        <PeriodRestriction
          control={control}
          register={register}
          watch={watch}
          errors={errors}
          disabled={isPending}
        />
      </Box>
    </form>
  );
};

export default CompanyBranchesCreate;
